require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./functions/0c3246e5c56c550fab7e1c02130cd287/code.js', () => { 

BackendlessUI.Functions.Custom['fn_0c3246e5c56c550fab7e1c02130cd287'] = function fn_0c3246e5c56c550fab7e1c02130cd287(property, propertyName, text) {
  if (property) {
    return ([propertyName,property,text].join(''));
  }

return 
}

})
define('./functions/4d44373a6f8154e50b5f3c2867692d73/code.js', () => { 

BackendlessUI.Functions.Custom['fn_4d44373a6f8154e50b5f3c2867692d73'] = async function fn_4d44373a6f8154e50b5f3c2867692d73(property, data, currentId) {
var result, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  result = (await Promise.all(data.map(async item => {  if (currentId == (getObjectProperty(item, property))) {
      item['current'] = true;
    } else {
      item['current'] = false;
    }
  ; return item;})));

return result
}

})
define('./functions/52e20369cd4779af0d886ea6e0ca7264/code.js', () => { 

BackendlessUI.Functions.Custom['fn_52e20369cd4779af0d886ea6e0ca7264'] = async function fn_52e20369cd4779af0d886ea6e0ca7264() {
  await (async function() {
  	window.location.reload()
  })();

}

})
define('./functions/6192e644ca94c88ed5c5b0e6fbfcb8b4/code.js', () => { 

BackendlessUI.Functions.Custom['fn_6192e644ca94c88ed5c5b0e6fbfcb8b4'] = function fn_6192e644ca94c88ed5c5b0e6fbfcb8b4(previousArray) {
  if (!previousArray.length) {
    return '';
  } else {
    return ' and ';
  }

return 
}

})
define('./functions/6844f9927747b47647a270fc9136452b/code.js', () => { 

BackendlessUI.Functions.Custom['fn_6844f9927747b47647a270fc9136452b'] = async function fn_6844f9927747b47647a270fc9136452b(socialList, socialName) {
var social, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string') {
    throw new Error('"property name" in "Get object property" block must be "string"')
  }

  if (object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object[propsNamesList[0]]

  for (let i = 1; i < propsNamesList.length; i++) {
    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  social = (await asyncListFilter(socialList, async (item) => {


   return ((getObjectProperty(item, 'name')) == socialName);
  }));
  ;await ( async function (url, isExternal, params){ const queryString = BackendlessUI.QueryString.stringify(params); const targetUrl = `${url}${queryString ? '?' + queryString : ''}`; isExternal ? window.open(targetUrl, '_blank') : window.location = targetUrl; })((getObjectProperty((social[0]), 'url')), true, null);

}

})
define('./functions/7606d747a136b98172f749df49ac5ba3/code.js', () => { 

var item, social;



BackendlessUI.Functions.Custom['fn_7606d747a136b98172f749df49ac5ba3'] = async function fn_7606d747a136b98172f749df49ac5ba3(socialList, socialName) {
    if (socialList && !!socialList.length) {
    social = (await (async () => {
      async function callback (item) {


        return ((item['name']) == socialName);
      }

      const list = await Promise.all(socialList.map(async source => ({
        source,
        value: await callback(source),
      })));

      const resultList = list.filter(item => item.value)

      return resultList.map(item => item.source)
    })());
    return true;
  } else {
    return false;
  }


  return
}

})
define('./functions/c67f868b60fa39474e9fcb25cd396c7f/code.js', () => { 

BackendlessUI.Functions.Custom['fn_c67f868b60fa39474e9fcb25cd396c7f'] = async function fn_c67f868b60fa39474e9fcb25cd396c7f(propertyList, propertyName, determiner, and) {
var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!!propertyList.length) {
    return ([and,'(',(await Promise.all(propertyList.map(async item => {; return ([propertyName,(getObjectProperty(item, 'objectId')),'\' '].join(''));}))).join(determiner),')'].join(''));
  }

return 
}

})
define('./functions/ffdf904a89675eddd985e45c70fec819/code.js', () => { 

var percentPrincipal, thirdAction, secondAction, firstAction, finalNum, staticNum, interest;



BackendlessUI.Functions.Custom['fn_ffdf904a89675eddd985e45c70fec819'] = async function fn_ffdf904a89675eddd985e45c70fec819(dataContainer, mortage, dougnut, dougnutItem) {
    if ((dataContainer['interest']) > 7300) {
    interest = 7300;
  } else {
    interest = (dataContainer['interest']);
  }
  if (interest) {
    dataContainer['i'] = (interest / 100);
  } else {
    dataContainer['i'] = 0;
  }
  dataContainer['P'] = ((dataContainer['price']) - (dataContainer['downPaymentDecimal']));
  if (dataContainer['i']) {
    dataContainer['I'] = ((dataContainer['i']) / 12);
  } else {
    dataContainer['I'] = 0;
  }
  dataContainer['n'] = ((dataContainer['term']) * 12);
  staticNum = 1 + (dataContainer['I']);
  finalNum = staticNum;
  var repeat_end = (dataContainer['n']) - 1;
  for (var count = 0; count < repeat_end; count++) {
    finalNum = finalNum * staticNum;
  }
  dataContainer['T'] = finalNum;
  firstAction = (dataContainer['I']) * (dataContainer['T']);
  secondAction = (dataContainer['T']) - 1;
  if (firstAction) {
    thirdAction = firstAction / secondAction;
  } else {
    thirdAction = 0;
  }
  dataContainer['M'] = ((dataContainer['P']) * thirdAction);
  dataContainer['sum'] = ((dataContainer['M']) + (dataContainer['otherDues']));
  if (dataContainer['M']) {
    percentPrincipal = ((dataContainer['M']) / (dataContainer['sum'])) * 100;
  } else {
    percentPrincipal = 0;
  }
  if (percentPrincipal < 50) {
    mortage['background-color'] = '#1ea69a';
    dougnut['z-index'] = '2';
  } else {
    mortage['background-color'] = '#ea723d';
    dougnut['z-index'] = '0';
  }
  dougnutItem['transform'] = (['rotate(',(percentPrincipal * 180) / 100,'deg)'].join(''));



}

})
define('./pages/404/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', undefined);

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', undefined);

  },
  /* handler:onClick *//* content */
}));

define('./pages/Login/components/85dae34486144e2efa2d934bfdddb172/bundle.js', [], () => ({
  /* content */

  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, stayLoggedIn, password, username, user, logging, buttonLogin;

/**
 * Describe this function...
 */
async function login() {
  try {
    user = (await Backendless.UserService.login( username, password, stayLoggedIn  ));

  } catch (error) {
    await setError(error);

  }
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setError(error) {
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('85dae34486144e2efa2d934bfdddb172'))['error'] = error;
}

/**
 * Describe this function...
 */
async function updateButtonLogin(logging) {
  buttonLogin = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'));
  buttonLogin['label'] = (logging ? 'Please wait...' : 'Login');
  buttonLogin['disabled'] = logging;
}


  await Backendless.UserService.logout();
  await setError(null);
  username = (getObjectProperty(___arguments.context.dataModel, 'username'));
  password = (getObjectProperty(___arguments.context.dataModel, 'password'));
  stayLoggedIn = (getObjectProperty(___arguments.context.dataModel, 'stayLoggedIn'));
  if (username && password) {
    await updateButtonLogin(true);
    try {
      await login();

    } catch (error) {
      if ((getObjectProperty(error, 'code')) == 3033) {
        await login();
      } else {
        await setError(error);
      }

    } finally {
      await updateButtonLogin(false);

    }
  } else {
    await setError((new Error('Username and Password must be filled')));
  }
  if (user) {
    ___arguments.context.appData['currentUser'] = user;
    localStorage.setItem('currentUser', JSON.stringify((await Backendless.UserService.getCurrentUser(false))));
    if ((getObjectProperty((await Backendless.Data.of('Users').findById(user, Backendless.DataQueryBuilder.create().setProperties('isPaid'))), 'isPaid')) == true) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://buy.stripe.com/6oEbJ61H99qL3ioeUY', false, undefined);
    } else if ((getObjectProperty((await Backendless.Data.of('Users').findById(user, Backendless.DataQueryBuilder.create().setProperties('isPaid'))), 'isPaid')) == false) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboard', ___arguments.context.appData);
    }
  }

  },
  /* handler:onSubmit *//* content */
}));

define('./pages/Login/components/6d7b09fc8c04707fa6291e69b80c05d1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('passwordRecovery', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Marketplace/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      if (!(await Backendless.UserService.isValidLogin())) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('welcome', undefined);
  }
  ___arguments.context.pageData['activeCount'] = 1;

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/Marketplace/components/5518b0fbd5b24e5fa12a754813788c88/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var pageSize, conditions, minPosition, maxPosition, userPositionSize, userRoles, profile, user_objectId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  userRoles = (await Backendless.UserService.getUserRoles());
  conditions = [];
  user_objectId = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId'));
  profile = (await Backendless.Data.of('Users').loadRelations(user_objectId, Backendless.LoadRelationsQueryBuilder.create().setRelationName('profile').setPageSize(10).setOffset(0).setProperties('positionSize')));
  userPositionSize = (getObjectProperty((profile[0]), 'positionSize'));
  if (userRoles.includes('GP')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer = \'All\'')));
    pageSize = 4;
    ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize)));
    ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
    ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
  }
  if (userRoles.includes('Equity')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer in (\'All\',\'Equity\',\'LP\')')));
    if (userPositionSize) {
      maxPosition = Math.max.apply(null, userPositionSize);
      minPosition = Math.min.apply(null, userPositionSize);
      addItemToList(conditions, (['(capitalAmount < ',maxPosition,' or privacySizeRestriction = false)'].join('')));
      addItemToList(conditions, (['(capitalAmount > ',minPosition,' or privacySizeRestriction = false)'].join('')));
      pageSize = 4;
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize)));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    } else {
      addItemToList(conditions, (String('privacySizeRestriction = false')));
      pageSize = 4;
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize)));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    }
  }
  if (userRoles.includes('Debt')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer in (\'All\',\'Debt\',\'LP\')')));
    if (userPositionSize) {
      maxPosition = Math.max.apply(null, userPositionSize);
      minPosition = Math.min.apply(null, userPositionSize);
      addItemToList(conditions, (['(capitalAmount < ',maxPosition,' or privacySizeRestriction = false)'].join('')));
      addItemToList(conditions, (['(capitalAmount > ',minPosition,' or privacySizeRestriction = false)'].join('')));
      pageSize = 4;
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize)));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    } else {
      addItemToList(conditions, (String('privacySizeRestriction = false')));
      pageSize = 4;
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize)));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    }
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/Marketplace/components/a0a2d6da957f6603743f4c081ce19158/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  async ['onSourceUrlAssignment'](___arguments) {
    var mainImage;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('d22e92d8be0e0b683e56cf9822b17238'), 'images')) {
    mainImage = (await asyncListFilter((getObjectProperty(___arguments.context.getComponentDataStoreByUid('d22e92d8be0e0b683e56cf9822b17238'), 'images')), async (mainImage) => {


     return ((getObjectProperty(mainImage, 'isMain')) == true);
    }));
    return (getObjectProperty((mainImage[0]), 'file'));
  } else {
    return 'https://www.creconnect.com/api/files/web/app/styles/images/Frame 1_webp.webp';
  }

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/Marketplace/components/63f8b88882ff15283592cdc8e058a21c/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
      ___arguments.context.dataModel['propertyType'] = [];
  ___arguments.context.dataModel['InvestmentStrategy'] = [];
  ___arguments.context.dataModel['capitalType'] = [];

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./pages/Marketplace/components/0a089268e05868787edb0268e6f834c8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0a089268e05868787edb0268e6f834c8', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('63f8b88882ff15283592cdc8e058a21c', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f3542d28ded1a8f88d11daa2a1383028', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e46850fe0c385b2711088d58d92e9368', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a5bbc61c46c88c482488852127d8462b', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Marketplace/components/a5bbc61c46c88c482488852127d8462b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a5bbc61c46c88c482488852127d8462b', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('63f8b88882ff15283592cdc8e058a21c', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f3542d28ded1a8f88d11daa2a1383028', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e46850fe0c385b2711088d58d92e9368', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0a089268e05868787edb0268e6f834c8', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Marketplace/components/4d286fb852c63028e70bc4d7e9984ef8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('propertyDetails', ({ 'objectId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('d22e92d8be0e0b683e56cf9822b17238'), 'objectId')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Marketplace/components/8ff53a12eb86707d40d68b2845389991/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['propertyType'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Marketplace/components/db8f90d1e9f8aea86a58a12511d4c1f8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['investmentStrategy'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Marketplace/components/0e38af983de8b15881c88be8ec0fa778/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['capitalType'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Marketplace/components/8a549ba8b386f5cc56c81248b0c889e8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var conditions, maxPosition, minPosition, pageSize, profile, user_objectId, userPositionSize, userRoles;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  userRoles = (await Backendless.UserService.getUserRoles());
  conditions = [];
  pageSize = 4;
  user_objectId = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId'));
  profile = (await Backendless.Data.of('Users').loadRelations(user_objectId, Backendless.LoadRelationsQueryBuilder.create().setRelationName('profile').setPageSize(10).setOffset(0).setProperties('positionSize')));
  userPositionSize = (getObjectProperty((profile[0]), 'positionSize'));
  if (getObjectProperty(___arguments.context.pageData, 'capitalType')) {
    if ((getObjectProperty(___arguments.context.pageData, 'capitalType')) != 'Any') {
      addItemToList(conditions, (['(capitalType = \'',(getObjectProperty(___arguments.context.pageData, 'capitalType')),'\')'].join('')));
    }
  }
  if (getObjectProperty(___arguments.context.pageData, 'propertyType')) {
    if ((getObjectProperty(___arguments.context.pageData, 'propertyType')) != 'Any') {
      addItemToList(conditions, (['(propertyType = \'',(getObjectProperty(___arguments.context.pageData, 'propertyType')),'\')'].join('')));
    }
  }
  if (getObjectProperty(___arguments.context.pageData, 'investmentStrategy')) {
    if ((getObjectProperty(___arguments.context.pageData, 'investmentStrategy')) != 'Any') {
      addItemToList(conditions, (['(investmentStrategy = \'',(getObjectProperty(___arguments.context.pageData, 'investmentStrategy')),'\')'].join('')));
    }
  }
  if (userRoles.includes('GP')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer = \'All\'')));
    ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize)));
    ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
    ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
  }
  if (userRoles.includes('Equity')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer in (\'All\',\'Equity\',\'LP\')')));
    if (userPositionSize) {
      maxPosition = Math.max.apply(null, userPositionSize);
      minPosition = Math.min.apply(null, userPositionSize);
      addItemToList(conditions, (['(capitalAmount < ',maxPosition,' or privacySizeRestriction = false)'].join('')));
      addItemToList(conditions, (['(capitalAmount > ',minPosition,' or privacySizeRestriction = false)'].join('')));
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize)));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    } else {
      addItemToList(conditions, (String('privacySizeRestriction = false')));
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize)));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    }
  }
  if (userRoles.includes('Debt')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer in (\'All\',\'Debt\',\'LP\')')));
    if (userPositionSize) {
      maxPosition = Math.max.apply(null, userPositionSize);
      minPosition = Math.min.apply(null, userPositionSize);
      addItemToList(conditions, (['(capitalAmount < ',maxPosition,' or privacySizeRestriction = false)'].join('')));
      addItemToList(conditions, (['(capitalAmount > ',minPosition,' or privacySizeRestriction = false)'].join('')));
      pageSize = 4;
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize)));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    } else {
      addItemToList(conditions, (String('privacySizeRestriction = false')));
      pageSize = 4;
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize)));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    }
  }
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).submit() })('63f8b88882ff15283592cdc8e058a21c');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Marketplace/components/f6c7eae819ae3f3a30d8b5d230985158/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var pageSize, conditions, userRoles, user_objectId, profile, userPositionSize, minPosition, maxPosition;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, value) { ___arguments.context.getComponentByUid(componentUid).value = value })('0e38af983de8b15881c88be8ec0fa778', undefined);
  (function (componentUid, value) { ___arguments.context.getComponentByUid(componentUid).value = value })('8ff53a12eb86707d40d68b2845389991', undefined);
  (function (componentUid, value) { ___arguments.context.getComponentByUid(componentUid).value = value })('db8f90d1e9f8aea86a58a12511d4c1f8', undefined);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('63f8b88882ff15283592cdc8e058a21c');
  delete ___arguments.context.pageData['propertyType'];
  delete ___arguments.context.pageData['investmentStrategy'];
  delete ___arguments.context.pageData['capitalType'];
  userRoles = (await Backendless.UserService.getUserRoles());
  conditions = [];
  user_objectId = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId'));
  profile = (await Backendless.Data.of('Users').loadRelations(user_objectId, Backendless.LoadRelationsQueryBuilder.create().setRelationName('profile').setPageSize(10).setOffset(0).setProperties('positionSize')));
  userPositionSize = (getObjectProperty((profile[0]), 'positionSize'));
  if (userRoles.includes('GP')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer = \'All\'')));
    pageSize = 4;
    ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize)));
    ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
    ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
  }
  if (userRoles.includes('Equity')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer in (\'All\',\'Equity\',\'LP\')')));
    if (userPositionSize) {
      maxPosition = Math.max.apply(null, userPositionSize);
      minPosition = Math.min.apply(null, userPositionSize);
      addItemToList(conditions, (['(capitalAmount < ',maxPosition,' or privacySizeRestriction = false)'].join('')));
      addItemToList(conditions, (['(capitalAmount > ',minPosition,' or privacySizeRestriction = false)'].join('')));
      pageSize = 4;
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize)));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    } else {
      addItemToList(conditions, (String('privacySizeRestriction = false')));
      pageSize = 4;
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize)));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    }
  }
  if (userRoles.includes('Debt')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer in (\'All\',\'Debt\',\'LP\')')));
    if (userPositionSize) {
      maxPosition = Math.max.apply(null, userPositionSize);
      minPosition = Math.min.apply(null, userPositionSize);
      addItemToList(conditions, (['(capitalAmount < ',maxPosition,' or privacySizeRestriction = false)'].join('')));
      addItemToList(conditions, (['(capitalAmount > ',minPosition,' or privacySizeRestriction = false)'].join('')));
      pageSize = 4;
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize)));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    } else {
      addItemToList(conditions, (String('privacySizeRestriction = false')));
      pageSize = 4;
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize)));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    }
  }
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('63f8b88882ff15283592cdc8e058a21c');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Marketplace/components/e4fcecf8af111bc533b8f7c88b78a7f7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboard', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Marketplace/components/22484188801d1cb14cc7d118b6555ab1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('myDeals', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Marketplace/components/f798cf7ac65866b87b229cfb3b3ddcf5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboardFavorites', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Marketplace/components/226889281bf87909ad64db5af024c568/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboardProfile', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Marketplace/components/89f8e708f0de59980c8890c802e8829b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      localStorage.removeItem('currentUser');
  await Backendless.UserService.logout();
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Marketplace/components/7e486b183a088df88e985b38b148d178/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('7e486b183a088df88e985b38b148d178')).slice(-1)[0] == 'active') {
    return (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('7e486b183a088df88e985b38b148d178')).pop() && ((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('9778320a2932b718d48827c42df9cac8')).pop());
  } else {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('7e486b183a088df88e985b38b148d178')), 'active');
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('9778320a2932b718d48827c42df9cac8')), 'active');
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Marketplace/components/8ab856f8b728f51d4514e71906611788/bundle.js', [], () => ({
  /* content */
  /* handler:onPageChange */
  async ['onPageChange'](___arguments) {
    var pageSize, conditions, minPosition, maxPosition, userPositionSize, userRoles, profile, user_objectId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  userRoles = (await Backendless.UserService.getUserRoles());
  conditions = [];
  user_objectId = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId'));
  profile = (await Backendless.Data.of('Users').loadRelations(user_objectId, Backendless.LoadRelationsQueryBuilder.create().setRelationName('profile').setPageSize(10).setOffset(0).setProperties('positionSize')));
  userPositionSize = (getObjectProperty((profile[0]), 'positionSize'));
  if (getObjectProperty(___arguments.context.pageData, 'capitalType')) {
    if ((getObjectProperty(___arguments.context.pageData, 'capitalType')) != 'Any') {
      addItemToList(conditions, (['(capitalType = \'',(getObjectProperty(___arguments.context.pageData, 'capitalType')),'\')'].join('')));
    }
  }
  if (getObjectProperty(___arguments.context.pageData, 'propertyType')) {
    if ((getObjectProperty(___arguments.context.pageData, 'propertyType')) != 'Any') {
      addItemToList(conditions, (['(propertyType = \'',(getObjectProperty(___arguments.context.pageData, 'propertyType')),'\')'].join('')));
    }
  }
  if (getObjectProperty(___arguments.context.pageData, 'investmentStrategy')) {
    if ((getObjectProperty(___arguments.context.pageData, 'investmentStrategy')) != 'Any') {
      addItemToList(conditions, (['(investmentStrategy = \'',(getObjectProperty(___arguments.context.pageData, 'investmentStrategy')),'\')'].join('')));
    }
  }
  ___arguments.context.pageData['currentPage'] = ___arguments['currentPage'];
  if (userRoles.includes('GP')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer = \'All\'')));
    pageSize = 4;
    ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
    ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
    ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
  }
  if (userRoles.includes('Equity')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer in (\'All\',\'Equity\',\'LP\')')));
    if (userPositionSize) {
      maxPosition = Math.max.apply(null, userPositionSize);
      minPosition = Math.min.apply(null, userPositionSize);
      addItemToList(conditions, (['(capitalAmount < ',maxPosition,' or privacySizeRestriction = false)'].join('')));
      addItemToList(conditions, (['(capitalAmount > ',minPosition,' or privacySizeRestriction = false)'].join('')));
      pageSize = 4;
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    } else {
      addItemToList(conditions, (String('privacySizeRestriction = false')));
      pageSize = 4;
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    }
  }
  if (userRoles.includes('Debt')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer in (\'All\',\'Debt\',\'LP\')')));
    if (userPositionSize) {
      maxPosition = Math.max.apply(null, userPositionSize);
      minPosition = Math.min.apply(null, userPositionSize);
      addItemToList(conditions, (['(capitalAmount < ',maxPosition,' or privacySizeRestriction = false)'].join('')));
      addItemToList(conditions, (['(capitalAmount > ',minPosition,' or privacySizeRestriction = false)'].join('')));
      pageSize = 4;
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    } else {
      addItemToList(conditions, (String('privacySizeRestriction = false')));
      pageSize = 4;
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    }
  }

  },
  /* handler:onPageChange */
  /* handler:onGoNext */
  async ['onGoNext'](___arguments) {
    var pageSize, conditions, minPosition, maxPosition, userPositionSize, userRoles, profile, user_objectId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  userRoles = (await Backendless.UserService.getUserRoles());
  conditions = [];
  user_objectId = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId'));
  profile = (await Backendless.Data.of('Users').loadRelations(user_objectId, Backendless.LoadRelationsQueryBuilder.create().setRelationName('profile').setPageSize(10).setOffset(0).setProperties('positionSize')));
  userPositionSize = (getObjectProperty((profile[0]), 'positionSize'));
  if (getObjectProperty(___arguments.context.pageData, 'capitalType')) {
    if ((getObjectProperty(___arguments.context.pageData, 'capitalType')) != 'Any') {
      addItemToList(conditions, (['(capitalType = \'',(getObjectProperty(___arguments.context.pageData, 'capitalType')),'\')'].join('')));
    }
  }
  if (getObjectProperty(___arguments.context.pageData, 'propertyType')) {
    if ((getObjectProperty(___arguments.context.pageData, 'propertyType')) != 'Any') {
      addItemToList(conditions, (['(propertyType = \'',(getObjectProperty(___arguments.context.pageData, 'propertyType')),'\')'].join('')));
    }
  }
  if (getObjectProperty(___arguments.context.pageData, 'investmentStrategy')) {
    if ((getObjectProperty(___arguments.context.pageData, 'investmentStrategy')) != 'Any') {
      addItemToList(conditions, (['(investmentStrategy = \'',(getObjectProperty(___arguments.context.pageData, 'investmentStrategy')),'\')'].join('')));
    }
  }
  ___arguments.context.pageData['currentPage'] = ((getObjectProperty(___arguments.context.pageData, 'currentPage')) + 1);
  if (userRoles.includes('GP')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer = \'All\'')));
    pageSize = 4;
    ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
    ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
    ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
  }
  if (userRoles.includes('Equity')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer in (\'All\',\'Equity\',\'LP\')')));
    if (userPositionSize) {
      maxPosition = Math.max.apply(null, userPositionSize);
      minPosition = Math.min.apply(null, userPositionSize);
      addItemToList(conditions, (['(capitalAmount < ',maxPosition,' or privacySizeRestriction = false)'].join('')));
      addItemToList(conditions, (['(capitalAmount > ',minPosition,' or privacySizeRestriction = false)'].join('')));
      pageSize = 4;
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    } else {
      addItemToList(conditions, (String('privacySizeRestriction = false')));
      pageSize = 4;
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    }
  }
  if (userRoles.includes('Debt')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer in (\'All\',\'Debt\',\'LP\')')));
    if (userPositionSize) {
      maxPosition = Math.max.apply(null, userPositionSize);
      minPosition = Math.min.apply(null, userPositionSize);
      addItemToList(conditions, (['(capitalAmount < ',maxPosition,' or privacySizeRestriction = false)'].join('')));
      addItemToList(conditions, (['(capitalAmount > ',minPosition,' or privacySizeRestriction = false)'].join('')));
      pageSize = 4;
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    } else {
      addItemToList(conditions, (String('privacySizeRestriction = false')));
      pageSize = 4;
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    }
  }
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).goNextPage() })('8ab856f8b728f51d4514e71906611788');

  },
  /* handler:onGoNext */
  /* handler:onGoBack */
  async ['onGoBack'](___arguments) {
    var pageSize, conditions, minPosition, maxPosition, userPositionSize, userRoles, profile, user_objectId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  userRoles = (await Backendless.UserService.getUserRoles());
  conditions = [];
  user_objectId = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId'));
  profile = (await Backendless.Data.of('Users').loadRelations(user_objectId, Backendless.LoadRelationsQueryBuilder.create().setRelationName('profile').setPageSize(10).setOffset(0).setProperties('positionSize')));
  userPositionSize = (getObjectProperty((profile[0]), 'positionSize'));
  if (getObjectProperty(___arguments.context.pageData, 'capitalType')) {
    if ((getObjectProperty(___arguments.context.pageData, 'capitalType')) != 'Any') {
      addItemToList(conditions, (['(capitalType = \'',(getObjectProperty(___arguments.context.pageData, 'capitalType')),'\')'].join('')));
    }
  }
  if (getObjectProperty(___arguments.context.pageData, 'propertyType')) {
    if ((getObjectProperty(___arguments.context.pageData, 'propertyType')) != 'Any') {
      addItemToList(conditions, (['(propertyType = \'',(getObjectProperty(___arguments.context.pageData, 'propertyType')),'\')'].join('')));
    }
  }
  if (getObjectProperty(___arguments.context.pageData, 'investmentStrategy')) {
    if ((getObjectProperty(___arguments.context.pageData, 'investmentStrategy')) != 'Any') {
      addItemToList(conditions, (['(investmentStrategy = \'',(getObjectProperty(___arguments.context.pageData, 'investmentStrategy')),'\')'].join('')));
    }
  }
  ___arguments.context.pageData['currentPage'] = ((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1);
  if (userRoles.includes('GP')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer = \'All\'')));
    pageSize = 4;
    ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
    ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
    ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
  }
  if (userRoles.includes('Equity')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer in (\'All\',\'Equity\',\'LP\')')));
    if (userPositionSize) {
      maxPosition = Math.max.apply(null, userPositionSize);
      minPosition = Math.min.apply(null, userPositionSize);
      addItemToList(conditions, (['(capitalAmount < ',maxPosition,' or privacySizeRestriction = false)'].join('')));
      addItemToList(conditions, (['(capitalAmount > ',minPosition,' or privacySizeRestriction = false)'].join('')));
      pageSize = 4;
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    } else {
      addItemToList(conditions, (String('privacySizeRestriction = false')));
      pageSize = 4;
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    }
  }
  if (userRoles.includes('Debt')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer in (\'All\',\'Debt\',\'LP\')')));
    if (userPositionSize) {
      maxPosition = Math.max.apply(null, userPositionSize);
      minPosition = Math.min.apply(null, userPositionSize);
      addItemToList(conditions, (['(capitalAmount < ',maxPosition,' or privacySizeRestriction = false)'].join('')));
      addItemToList(conditions, (['(capitalAmount > ',minPosition,' or privacySizeRestriction = false)'].join('')));
      pageSize = 4;
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    } else {
      addItemToList(conditions, (String('privacySizeRestriction = false')));
      pageSize = 4;
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    }
  }
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).goPreviousPage() })('8ab856f8b728f51d4514e71906611788');

  },
  /* handler:onGoBack */
  /* handler:onGoFirst */
  async ['onGoFirst'](___arguments) {
    var pageSize, conditions, minPosition, maxPosition, userPositionSize, userRoles, profile, user_objectId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  userRoles = (await Backendless.UserService.getUserRoles());
  conditions = [];
  user_objectId = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId'));
  profile = (await Backendless.Data.of('Users').loadRelations(user_objectId, Backendless.LoadRelationsQueryBuilder.create().setRelationName('profile').setPageSize(10).setOffset(0).setProperties('positionSize')));
  userPositionSize = (getObjectProperty((profile[0]), 'positionSize'));
  if (getObjectProperty(___arguments.context.pageData, 'capitalType')) {
    if ((getObjectProperty(___arguments.context.pageData, 'capitalType')) != 'Any') {
      addItemToList(conditions, (['(capitalType = \'',(getObjectProperty(___arguments.context.pageData, 'capitalType')),'\')'].join('')));
    }
  }
  if (getObjectProperty(___arguments.context.pageData, 'propertyType')) {
    if ((getObjectProperty(___arguments.context.pageData, 'propertyType')) != 'Any') {
      addItemToList(conditions, (['(propertyType = \'',(getObjectProperty(___arguments.context.pageData, 'propertyType')),'\')'].join('')));
    }
  }
  if (getObjectProperty(___arguments.context.pageData, 'investmentStrategy')) {
    if ((getObjectProperty(___arguments.context.pageData, 'investmentStrategy')) != 'Any') {
      addItemToList(conditions, (['(investmentStrategy = \'',(getObjectProperty(___arguments.context.pageData, 'investmentStrategy')),'\')'].join('')));
    }
  }
  ___arguments.context.pageData['currentPage'] = 1;
  if (userRoles.includes('GP')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer = \'All\'')));
    pageSize = 4;
    ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
    ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
    ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
  }
  if (userRoles.includes('Equity')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer in (\'All\',\'Equity\',\'LP\')')));
    if (userPositionSize) {
      maxPosition = Math.max.apply(null, userPositionSize);
      minPosition = Math.min.apply(null, userPositionSize);
      addItemToList(conditions, (['(capitalAmount < ',maxPosition,' or privacySizeRestriction = false)'].join('')));
      addItemToList(conditions, (['(capitalAmount > ',minPosition,' or privacySizeRestriction = false)'].join('')));
      pageSize = 4;
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    } else {
      addItemToList(conditions, (String('privacySizeRestriction = false')));
      pageSize = 4;
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    }
  }
  if (userRoles.includes('Debt')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer in (\'All\',\'Debt\',\'LP\')')));
    if (userPositionSize) {
      maxPosition = Math.max.apply(null, userPositionSize);
      minPosition = Math.min.apply(null, userPositionSize);
      addItemToList(conditions, (['(capitalAmount < ',maxPosition,' or privacySizeRestriction = false)'].join('')));
      addItemToList(conditions, (['(capitalAmount > ',minPosition,' or privacySizeRestriction = false)'].join('')));
      pageSize = 4;
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    } else {
      addItemToList(conditions, (String('privacySizeRestriction = false')));
      pageSize = 4;
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    }
  }
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).goFirstPage() })('8ab856f8b728f51d4514e71906611788');

  },
  /* handler:onGoFirst */
  /* handler:onGoLast */
  async ['onGoLast'](___arguments) {
    var pageSize, conditions, minPosition, maxPosition, userPositionSize, userRoles, profile, user_objectId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  userRoles = (await Backendless.UserService.getUserRoles());
  conditions = [];
  user_objectId = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId'));
  profile = (await Backendless.Data.of('Users').loadRelations(user_objectId, Backendless.LoadRelationsQueryBuilder.create().setRelationName('profile').setPageSize(10).setOffset(0).setProperties('positionSize')));
  userPositionSize = (getObjectProperty((profile[0]), 'positionSize'));
  if (getObjectProperty(___arguments.context.pageData, 'capitalType')) {
    if ((getObjectProperty(___arguments.context.pageData, 'capitalType')) != 'Any') {
      addItemToList(conditions, (['(capitalType = \'',(getObjectProperty(___arguments.context.pageData, 'capitalType')),'\')'].join('')));
    }
  }
  if (getObjectProperty(___arguments.context.pageData, 'propertyType')) {
    if ((getObjectProperty(___arguments.context.pageData, 'propertyType')) != 'Any') {
      addItemToList(conditions, (['(propertyType = \'',(getObjectProperty(___arguments.context.pageData, 'propertyType')),'\')'].join('')));
    }
  }
  if (getObjectProperty(___arguments.context.pageData, 'investmentStrategy')) {
    if ((getObjectProperty(___arguments.context.pageData, 'investmentStrategy')) != 'Any') {
      addItemToList(conditions, (['(investmentStrategy = \'',(getObjectProperty(___arguments.context.pageData, 'investmentStrategy')),'\')'].join('')));
    }
  }
  ___arguments.context.pageData['currentPage'] = (getObjectProperty(___arguments.context.pageData, 'pageCount'));
  if (userRoles.includes('GP')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer = \'All\'')));
    pageSize = 4;
    ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
    ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
    ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
  }
  if (userRoles.includes('Equity')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer in (\'All\',\'Equity\',\'LP\')')));
    if (userPositionSize) {
      maxPosition = Math.max.apply(null, userPositionSize);
      minPosition = Math.min.apply(null, userPositionSize);
      addItemToList(conditions, (['(capitalAmount < ',maxPosition,' or privacySizeRestriction = false)'].join('')));
      addItemToList(conditions, (['(capitalAmount > ',minPosition,' or privacySizeRestriction = false)'].join('')));
      pageSize = 4;
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    } else {
      addItemToList(conditions, (String('privacySizeRestriction = false')));
      pageSize = 4;
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    }
  }
  if (userRoles.includes('Debt')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer in (\'All\',\'Debt\',\'LP\')')));
    if (userPositionSize) {
      maxPosition = Math.max.apply(null, userPositionSize);
      minPosition = Math.min.apply(null, userPositionSize);
      addItemToList(conditions, (['(capitalAmount < ',maxPosition,' or privacySizeRestriction = false)'].join('')));
      addItemToList(conditions, (['(capitalAmount > ',minPosition,' or privacySizeRestriction = false)'].join('')));
      pageSize = 4;
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    } else {
      addItemToList(conditions, (String('privacySizeRestriction = false')));
      pageSize = 4;
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    }
  }
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).goLastPage() })('8ab856f8b728f51d4514e71906611788');

  },
  /* handler:onGoLast */
  /* content */
}))

define('./pages/Marketplace/components/e46850fe0c385b2711088d58d92e9368/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
      ___arguments.context.pageData['pageView'] = true;

  },
  /* handler:onMounted */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'pageView')) == false) {
    ___arguments.context.pageData['pageView'] = true;
  }
  if ((getObjectProperty(___arguments.context.pageData, 'listView')) == true) {
    ___arguments.context.pageData['listView'] = false;
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5518b0fbd5b24e5fa12a754813788c88', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4e6e542c014f259965ad9797e478d778', false);

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'pageView')) == true) {
    return ['view__type', 'active'];
  }

  return ['view__type']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Marketplace/components/f3542d28ded1a8f88d11daa2a1383028/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
      ___arguments.context.pageData['listView'] = false;

  },
  /* handler:onMounted */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var pageSize, conditions, minPosition, maxPosition, userPositionSize, userRoles, profile, user_objectId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'listView')) == false) {
    ___arguments.context.pageData['listView'] = true;
  }
  if ((getObjectProperty(___arguments.context.pageData, 'pageView')) == true) {
    ___arguments.context.pageData['pageView'] = false;
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5518b0fbd5b24e5fa12a754813788c88', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4e6e542c014f259965ad9797e478d778', true);

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'listView')) == true) {
    return ['view__type', 'active'];
  }

  return ['view__type']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Marketplace/components/9938eff822ec5f680bf83f714036ec48/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'listView')) == true) {
    return ['view__type', 'active'];
  }

  return ['view__type']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Marketplace/components/9288a69836785288fb88375dddb839f8/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'pageView')) == true) {
    return ['view__type', 'active'];
  }

  return ['view__type']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Marketplace/components/bc42eed89a32b94fe3a829d841cdaa78/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'pageView')) == true) {
    return ['view__type', 'active'];
  }

  return ['view__type']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Marketplace/components/33ca60e8f7747a885f38d5888be846b8/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'listView')) == true) {
    return ['view__type', 'active'];
  }

  return ['view__type']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Marketplace/components/1c18c2f8a648f0a8989d83e630e8f078/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('2fb81ec89438f3983cf893b89da8ba88'), 'capitalAmount')) {
    return (String('$') + String((JSON.stringify((getObjectProperty(___arguments.context.getComponentDataStoreByUid('2fb81ec89438f3983cf893b89da8ba88'), 'capitalAmount')))).replace((new RegExp('\\B(?=(\\d{3})+(?!\\d))', 'g')), ',')));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Marketplace/components/2fb81ec89438f3983cf893b89da8ba88/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('propertyDetails', ({ 'objectId': (getObjectProperty(___arguments.context.dataModel, 'objectId')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Marketplace/components/c39c670898223bfc722803b817b8a9ad/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var pageSize, conditions, minPosition, maxPosition, userPositionSize, userRoles, profile, user_objectId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  userRoles = (await Backendless.UserService.getUserRoles());
  conditions = [];
  user_objectId = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId'));
  profile = (await Backendless.Data.of('Users').loadRelations(user_objectId, Backendless.LoadRelationsQueryBuilder.create().setRelationName('profile').setPageSize(10).setOffset(0).setProperties('positionSize')));
  userPositionSize = (getObjectProperty((profile[0]), 'positionSize'));
  if (getObjectProperty(___arguments.context.pageData, 'capitalType')) {
    if ((getObjectProperty(___arguments.context.pageData, 'capitalType')) != 'Any') {
      addItemToList(conditions, (['(capitalType = \'',(getObjectProperty(___arguments.context.pageData, 'capitalType')),'\')'].join('')));
    }
  }
  if (getObjectProperty(___arguments.context.pageData, 'propertyType')) {
    if ((getObjectProperty(___arguments.context.pageData, 'propertyType')) != 'Any') {
      addItemToList(conditions, (['(propertyType = \'',(getObjectProperty(___arguments.context.pageData, 'propertyType')),'\')'].join('')));
    }
  }
  if (getObjectProperty(___arguments.context.pageData, 'investmentStrategy')) {
    if ((getObjectProperty(___arguments.context.pageData, 'investmentStrategy')) != 'Any') {
      addItemToList(conditions, (['(investmentStrategy = \'',(getObjectProperty(___arguments.context.pageData, 'investmentStrategy')),'\')'].join('')));
    }
  }
  if (userRoles.includes('GP')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer = \'All\'')));
    pageSize = (Number(___arguments.value));
    ___arguments.context.pageData['rowSize'] = (Number(___arguments.value));
    ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize)));
  }
  if (userRoles.includes('Equity')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer in (\'All\',\'Equity\',\'LP\')')));
    if (userPositionSize) {
      maxPosition = Math.max.apply(null, userPositionSize);
      minPosition = Math.min.apply(null, userPositionSize);
      addItemToList(conditions, (['(capitalAmount < ',maxPosition,' or privacySizeRestriction = false)'].join('')));
      addItemToList(conditions, (['(capitalAmount > ',minPosition,' or privacySizeRestriction = false)'].join('')));
      pageSize = (Number(___arguments.value));
      ___arguments.context.pageData['rowSize'] = (Number(___arguments.value));
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize)));
    } else {
      addItemToList(conditions, (String('privacySizeRestriction = false')));
      pageSize = (Number(___arguments.value));
      ___arguments.context.pageData['rowSize'] = (Number(___arguments.value));
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize)));
    }
  }
  if (userRoles.includes('Debt')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer in (\'All\',\'Debt\',\'LP\')')));
    if (userPositionSize) {
      maxPosition = Math.max.apply(null, userPositionSize);
      minPosition = Math.min.apply(null, userPositionSize);
      addItemToList(conditions, (['(capitalAmount < ',maxPosition,' or privacySizeRestriction = false)'].join('')));
      addItemToList(conditions, (['(capitalAmount > ',minPosition,' or privacySizeRestriction = false)'].join('')));
      pageSize = (Number(___arguments.value));
      ___arguments.context.pageData['rowSize'] = (Number(___arguments.value));
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize)));
    } else {
      addItemToList(conditions, (String('privacySizeRestriction = false')));
      pageSize = (Number(___arguments.value));
      ___arguments.context.pageData['rowSize'] = (Number(___arguments.value));
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize)));
    }
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Marketplace/components/b668f9089c413afb22484d687778c1ed/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var pageSize, conditions, minPosition, maxPosition, userPositionSize, userRoles, profile, user_objectId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  userRoles = (await Backendless.UserService.getUserRoles());
  conditions = [];
  user_objectId = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId'));
  profile = (await Backendless.Data.of('Users').loadRelations(user_objectId, Backendless.LoadRelationsQueryBuilder.create().setRelationName('profile').setPageSize(10).setOffset(0).setProperties('positionSize')));
  userPositionSize = (getObjectProperty((profile[0]), 'positionSize'));
  ___arguments.context.pageData['rowSize'] = 20;
  if (getObjectProperty(___arguments.context.pageData, 'capitalType')) {
    if ((getObjectProperty(___arguments.context.pageData, 'capitalType')) != 'Any') {
      addItemToList(conditions, (['(capitalType = \'',(getObjectProperty(___arguments.context.pageData, 'capitalType')),'\')'].join('')));
    }
  }
  if (getObjectProperty(___arguments.context.pageData, 'propertyType')) {
    if ((getObjectProperty(___arguments.context.pageData, 'propertyType')) != 'Any') {
      addItemToList(conditions, (['(propertyType = \'',(getObjectProperty(___arguments.context.pageData, 'propertyType')),'\')'].join('')));
    }
  }
  if (getObjectProperty(___arguments.context.pageData, 'investmentStrategy')) {
    if ((getObjectProperty(___arguments.context.pageData, 'investmentStrategy')) != 'Any') {
      addItemToList(conditions, (['(investmentStrategy = \'',(getObjectProperty(___arguments.context.pageData, 'investmentStrategy')),'\')'].join('')));
    }
  }
  if (userRoles.includes('GP')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer = \'All\'')));
    pageSize = 20;
    ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize)));
  }
  if (userRoles.includes('Equity')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer in (\'All\',\'Equity\',\'LP\')')));
    if (userPositionSize) {
      maxPosition = Math.max.apply(null, userPositionSize);
      minPosition = Math.min.apply(null, userPositionSize);
      addItemToList(conditions, (['(capitalAmount < ',maxPosition,' or privacySizeRestriction = false)'].join('')));
      addItemToList(conditions, (['(capitalAmount > ',minPosition,' or privacySizeRestriction = false)'].join('')));
      pageSize = 20;
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize)));
    } else {
      addItemToList(conditions, (String('privacySizeRestriction = false')));
      pageSize = 20;
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize)));
    }
  }
  if (userRoles.includes('Debt')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer in (\'All\',\'Debt\',\'LP\')')));
    if (userPositionSize) {
      maxPosition = Math.max.apply(null, userPositionSize);
      minPosition = Math.min.apply(null, userPositionSize);
      addItemToList(conditions, (['(capitalAmount < ',maxPosition,' or privacySizeRestriction = false)'].join('')));
      addItemToList(conditions, (['(capitalAmount > ',minPosition,' or privacySizeRestriction = false)'].join('')));
      pageSize = 20;
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize)));
    } else {
      addItemToList(conditions, (String('privacySizeRestriction = false')));
      pageSize = 20;
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize)));
    }
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/Marketplace/components/541808a833e87938e94e6e453448cb18/bundle.js', [], () => ({
  /* content */
  /* handler:onPageChange */
  async ['onPageChange'](___arguments) {
    var pageSize, conditions, minPosition, maxPosition, userPositionSize, userRoles, profile, user_objectId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  userRoles = (await Backendless.UserService.getUserRoles());
  conditions = [];
  user_objectId = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId'));
  profile = (await Backendless.Data.of('Users').loadRelations(user_objectId, Backendless.LoadRelationsQueryBuilder.create().setRelationName('profile').setPageSize(10).setOffset(0).setProperties('positionSize')));
  userPositionSize = (getObjectProperty((profile[0]), 'positionSize'));
  if (getObjectProperty(___arguments.context.pageData, 'capitalType')) {
    if ((getObjectProperty(___arguments.context.pageData, 'capitalType')) != 'Any') {
      addItemToList(conditions, (['(capitalType = \'',(getObjectProperty(___arguments.context.pageData, 'capitalType')),'\')'].join('')));
    }
  }
  if (getObjectProperty(___arguments.context.pageData, 'propertyType')) {
    if ((getObjectProperty(___arguments.context.pageData, 'propertyType')) != 'Any') {
      addItemToList(conditions, (['(propertyType = \'',(getObjectProperty(___arguments.context.pageData, 'propertyType')),'\')'].join('')));
    }
  }
  if (getObjectProperty(___arguments.context.pageData, 'investmentStrategy')) {
    if ((getObjectProperty(___arguments.context.pageData, 'investmentStrategy')) != 'Any') {
      addItemToList(conditions, (['(investmentStrategy = \'',(getObjectProperty(___arguments.context.pageData, 'investmentStrategy')),'\')'].join('')));
    }
  }
  ___arguments.context.pageData['currentPage'] = ___arguments['currentPage'];
  if (userRoles.includes('GP')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer = \'All\'')));
    pageSize = (getObjectProperty(___arguments.context.pageData, 'rowSize'));
    ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
    ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
    ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
  }
  if (userRoles.includes('Equity')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer in (\'All\',\'Equity\',\'LP\')')));
    if (userPositionSize) {
      maxPosition = Math.max.apply(null, userPositionSize);
      minPosition = Math.min.apply(null, userPositionSize);
      addItemToList(conditions, (['(capitalAmount < ',maxPosition,' or privacySizeRestriction = false)'].join('')));
      addItemToList(conditions, (['(capitalAmount > ',minPosition,' or privacySizeRestriction = false)'].join('')));
      pageSize = (getObjectProperty(___arguments.context.pageData, 'rowSize'));
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    } else {
      addItemToList(conditions, (String('privacySizeRestriction = false')));
      pageSize = (getObjectProperty(___arguments.context.pageData, 'rowSize'));
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    }
  }
  if (userRoles.includes('Debt')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer in (\'All\',\'Debt\',\'LP\')')));
    if (userPositionSize) {
      maxPosition = Math.max.apply(null, userPositionSize);
      minPosition = Math.min.apply(null, userPositionSize);
      addItemToList(conditions, (['(capitalAmount < ',maxPosition,' or privacySizeRestriction = false)'].join('')));
      addItemToList(conditions, (['(capitalAmount > ',minPosition,' or privacySizeRestriction = false)'].join('')));
      pageSize = (getObjectProperty(___arguments.context.pageData, 'rowSize'));
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    } else {
      addItemToList(conditions, (String('privacySizeRestriction = false')));
      pageSize = (getObjectProperty(___arguments.context.pageData, 'rowSize'));
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    }
  }

  },
  /* handler:onPageChange */
  /* handler:onGoNext */
  async ['onGoNext'](___arguments) {
    var userRoles, conditions, user_objectId, profile, userPositionSize, pageSize, minPosition, maxPosition;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  userRoles = (await Backendless.UserService.getUserRoles());
  conditions = [];
  user_objectId = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId'));
  profile = (await Backendless.Data.of('Users').loadRelations(user_objectId, Backendless.LoadRelationsQueryBuilder.create().setRelationName('profile').setPageSize(10).setOffset(0).setProperties('positionSize')));
  userPositionSize = (getObjectProperty((profile[0]), 'positionSize'));
  if (getObjectProperty(___arguments.context.pageData, 'capitalType')) {
    if ((getObjectProperty(___arguments.context.pageData, 'capitalType')) != 'Any') {
      addItemToList(conditions, (['(capitalType = \'',(getObjectProperty(___arguments.context.pageData, 'capitalType')),'\')'].join('')));
    }
  }
  if (getObjectProperty(___arguments.context.pageData, 'propertyType')) {
    if ((getObjectProperty(___arguments.context.pageData, 'propertyType')) != 'Any') {
      addItemToList(conditions, (['(propertyType = \'',(getObjectProperty(___arguments.context.pageData, 'propertyType')),'\')'].join('')));
    }
  }
  if (getObjectProperty(___arguments.context.pageData, 'investmentStrategy')) {
    if ((getObjectProperty(___arguments.context.pageData, 'investmentStrategy')) != 'Any') {
      addItemToList(conditions, (['(investmentStrategy = \'',(getObjectProperty(___arguments.context.pageData, 'investmentStrategy')),'\')'].join('')));
    }
  }
  ___arguments.context.pageData['currentPage'] = ((getObjectProperty(___arguments.context.pageData, 'currentPage')) + 1);
  if (userRoles.includes('GP')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer = \'All\'')));
    pageSize = (getObjectProperty(___arguments.context.pageData, 'rowSize'));
    ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
    ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
    ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
  }
  if (userRoles.includes('Equity')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer in (\'All\',\'Equity\',\'LP\')')));
    if (userPositionSize) {
      maxPosition = Math.max.apply(null, userPositionSize);
      minPosition = Math.min.apply(null, userPositionSize);
      addItemToList(conditions, (['(capitalAmount < ',maxPosition,' or privacySizeRestriction = false)'].join('')));
      addItemToList(conditions, (['(capitalAmount > ',minPosition,' or privacySizeRestriction = false)'].join('')));
      pageSize = (getObjectProperty(___arguments.context.pageData, 'rowSize'));
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    } else {
      addItemToList(conditions, (String('privacySizeRestriction = false')));
      pageSize = (getObjectProperty(___arguments.context.pageData, 'rowSize'));
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    }
  }
  if (userRoles.includes('Debt')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer in (\'All\',\'Debt\',\'LP\')')));
    if (userPositionSize) {
      maxPosition = Math.max.apply(null, userPositionSize);
      minPosition = Math.min.apply(null, userPositionSize);
      addItemToList(conditions, (['(capitalAmount < ',maxPosition,' or privacySizeRestriction = false)'].join('')));
      addItemToList(conditions, (['(capitalAmount > ',minPosition,' or privacySizeRestriction = false)'].join('')));
      pageSize = (getObjectProperty(___arguments.context.pageData, 'rowSize'));
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    } else {
      addItemToList(conditions, (String('privacySizeRestriction = false')));
      pageSize = (getObjectProperty(___arguments.context.pageData, 'rowSize'));
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    }
  }

  },
  /* handler:onGoNext */
  /* handler:onGoBack */
  async ['onGoBack'](___arguments) {
    var userRoles, conditions, user_objectId, profile, userPositionSize, pageSize, minPosition, maxPosition;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  userRoles = (await Backendless.UserService.getUserRoles());
  conditions = [];
  user_objectId = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId'));
  profile = (await Backendless.Data.of('Users').loadRelations(user_objectId, Backendless.LoadRelationsQueryBuilder.create().setRelationName('profile').setPageSize(10).setOffset(0).setProperties('positionSize')));
  userPositionSize = (getObjectProperty((profile[0]), 'positionSize'));
  if (getObjectProperty(___arguments.context.pageData, 'capitalType')) {
    if ((getObjectProperty(___arguments.context.pageData, 'capitalType')) != 'Any') {
      addItemToList(conditions, (['(capitalType = \'',(getObjectProperty(___arguments.context.pageData, 'capitalType')),'\')'].join('')));
    }
  }
  if (getObjectProperty(___arguments.context.pageData, 'propertyType')) {
    if ((getObjectProperty(___arguments.context.pageData, 'propertyType')) != 'Any') {
      addItemToList(conditions, (['(propertyType = \'',(getObjectProperty(___arguments.context.pageData, 'propertyType')),'\')'].join('')));
    }
  }
  if (getObjectProperty(___arguments.context.pageData, 'investmentStrategy')) {
    if ((getObjectProperty(___arguments.context.pageData, 'investmentStrategy')) != 'Any') {
      addItemToList(conditions, (['(investmentStrategy = \'',(getObjectProperty(___arguments.context.pageData, 'investmentStrategy')),'\')'].join('')));
    }
  }
  ___arguments.context.pageData['currentPage'] = ((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1);
  if (userRoles.includes('GP')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer = \'All\'')));
    pageSize = (getObjectProperty(___arguments.context.pageData, 'rowSize'));
    ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
    ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
    ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
  }
  if (userRoles.includes('Equity')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer in (\'All\',\'Equity\',\'LP\')')));
    if (userPositionSize) {
      maxPosition = Math.max.apply(null, userPositionSize);
      minPosition = Math.min.apply(null, userPositionSize);
      addItemToList(conditions, (['(capitalAmount < ',maxPosition,' or privacySizeRestriction = false)'].join('')));
      addItemToList(conditions, (['(capitalAmount > ',minPosition,' or privacySizeRestriction = false)'].join('')));
      pageSize = (getObjectProperty(___arguments.context.pageData, 'rowSize'));
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    } else {
      addItemToList(conditions, (String('privacySizeRestriction = false')));
      pageSize = (getObjectProperty(___arguments.context.pageData, 'rowSize'));
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    }
  }
  if (userRoles.includes('Debt')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer in (\'All\',\'Debt\',\'LP\')')));
    if (userPositionSize) {
      maxPosition = Math.max.apply(null, userPositionSize);
      minPosition = Math.min.apply(null, userPositionSize);
      addItemToList(conditions, (['(capitalAmount < ',maxPosition,' or privacySizeRestriction = false)'].join('')));
      addItemToList(conditions, (['(capitalAmount > ',minPosition,' or privacySizeRestriction = false)'].join('')));
      pageSize = (getObjectProperty(___arguments.context.pageData, 'rowSize'));
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    } else {
      addItemToList(conditions, (String('privacySizeRestriction = false')));
      pageSize = (getObjectProperty(___arguments.context.pageData, 'rowSize'));
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    }
  }

  },
  /* handler:onGoBack */
  /* handler:onGoFirst */
  async ['onGoFirst'](___arguments) {
    var userRoles, conditions, user_objectId, profile, userPositionSize, pageSize, minPosition, maxPosition;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  userRoles = (await Backendless.UserService.getUserRoles());
  conditions = [];
  user_objectId = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId'));
  profile = (await Backendless.Data.of('Users').loadRelations(user_objectId, Backendless.LoadRelationsQueryBuilder.create().setRelationName('profile').setPageSize(10).setOffset(0).setProperties('positionSize')));
  userPositionSize = (getObjectProperty((profile[0]), 'positionSize'));
  if (getObjectProperty(___arguments.context.pageData, 'capitalType')) {
    if ((getObjectProperty(___arguments.context.pageData, 'capitalType')) != 'Any') {
      addItemToList(conditions, (['(capitalType = \'',(getObjectProperty(___arguments.context.pageData, 'capitalType')),'\')'].join('')));
    }
  }
  if (getObjectProperty(___arguments.context.pageData, 'propertyType')) {
    if ((getObjectProperty(___arguments.context.pageData, 'propertyType')) != 'Any') {
      addItemToList(conditions, (['(propertyType = \'',(getObjectProperty(___arguments.context.pageData, 'propertyType')),'\')'].join('')));
    }
  }
  if (getObjectProperty(___arguments.context.pageData, 'investmentStrategy')) {
    if ((getObjectProperty(___arguments.context.pageData, 'investmentStrategy')) != 'Any') {
      addItemToList(conditions, (['(investmentStrategy = \'',(getObjectProperty(___arguments.context.pageData, 'investmentStrategy')),'\')'].join('')));
    }
  }
  ___arguments.context.pageData['currentPage'] = 1;
  if (userRoles.includes('GP')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer = \'All\'')));
    pageSize = (getObjectProperty(___arguments.context.pageData, 'rowSize'));
    ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
    ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
    ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
  }
  if (userRoles.includes('Equity')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer in (\'All\',\'Equity\',\'LP\')')));
    if (userPositionSize) {
      maxPosition = Math.max.apply(null, userPositionSize);
      minPosition = Math.min.apply(null, userPositionSize);
      addItemToList(conditions, (['(capitalAmount < ',maxPosition,' or privacySizeRestriction = false)'].join('')));
      addItemToList(conditions, (['(capitalAmount > ',minPosition,' or privacySizeRestriction = false)'].join('')));
      pageSize = (getObjectProperty(___arguments.context.pageData, 'rowSize'));
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    } else {
      addItemToList(conditions, (String('privacySizeRestriction = false')));
      pageSize = (getObjectProperty(___arguments.context.pageData, 'rowSize'));
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    }
  }
  if (userRoles.includes('Debt')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer in (\'All\',\'Debt\',\'LP\')')));
    if (userPositionSize) {
      maxPosition = Math.max.apply(null, userPositionSize);
      minPosition = Math.min.apply(null, userPositionSize);
      addItemToList(conditions, (['(capitalAmount < ',maxPosition,' or privacySizeRestriction = false)'].join('')));
      addItemToList(conditions, (['(capitalAmount > ',minPosition,' or privacySizeRestriction = false)'].join('')));
      pageSize = (getObjectProperty(___arguments.context.pageData, 'rowSize'));
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    } else {
      addItemToList(conditions, (String('privacySizeRestriction = false')));
      pageSize = (getObjectProperty(___arguments.context.pageData, 'rowSize'));
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    }
  }

  },
  /* handler:onGoFirst */
  /* handler:onGoLast */
  async ['onGoLast'](___arguments) {
    var userRoles, conditions, user_objectId, profile, userPositionSize, pageSize, minPosition, maxPosition;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  userRoles = (await Backendless.UserService.getUserRoles());
  conditions = [];
  user_objectId = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId'));
  profile = (await Backendless.Data.of('Users').loadRelations(user_objectId, Backendless.LoadRelationsQueryBuilder.create().setRelationName('profile').setPageSize(10).setOffset(0).setProperties('positionSize')));
  userPositionSize = (getObjectProperty((profile[0]), 'positionSize'));
  if (getObjectProperty(___arguments.context.pageData, 'capitalType')) {
    if ((getObjectProperty(___arguments.context.pageData, 'capitalType')) != 'Any') {
      addItemToList(conditions, (['(capitalType = \'',(getObjectProperty(___arguments.context.pageData, 'capitalType')),'\')'].join('')));
    }
  }
  if (getObjectProperty(___arguments.context.pageData, 'propertyType')) {
    if ((getObjectProperty(___arguments.context.pageData, 'propertyType')) != 'Any') {
      addItemToList(conditions, (['(propertyType = \'',(getObjectProperty(___arguments.context.pageData, 'propertyType')),'\')'].join('')));
    }
  }
  if (getObjectProperty(___arguments.context.pageData, 'investmentStrategy')) {
    if ((getObjectProperty(___arguments.context.pageData, 'investmentStrategy')) != 'Any') {
      addItemToList(conditions, (['(investmentStrategy = \'',(getObjectProperty(___arguments.context.pageData, 'investmentStrategy')),'\')'].join('')));
    }
  }
  ___arguments.context.pageData['currentPage'] = (getObjectProperty(___arguments.context.pageData, 'pageCount'));
  if (userRoles.includes('GP')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer = \'All\'')));
    pageSize = (getObjectProperty(___arguments.context.pageData, 'rowSize'));
    ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
    ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
    ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
  }
  if (userRoles.includes('Equity')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer in (\'All\',\'Equity\',\'LP\')')));
    if (userPositionSize) {
      maxPosition = Math.max.apply(null, userPositionSize);
      minPosition = Math.min.apply(null, userPositionSize);
      addItemToList(conditions, (['(capitalAmount < ',maxPosition,' or privacySizeRestriction = false)'].join('')));
      addItemToList(conditions, (['(capitalAmount > ',minPosition,' or privacySizeRestriction = false)'].join('')));
      pageSize = (getObjectProperty(___arguments.context.pageData, 'rowSize'));
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    } else {
      addItemToList(conditions, (String('privacySizeRestriction = false')));
      pageSize = (getObjectProperty(___arguments.context.pageData, 'rowSize'));
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    }
  }
  if (userRoles.includes('Debt')) {
    addItemToList(conditions, (String('isApproved = true and isLive = true and privacyViewer in (\'All\',\'Debt\',\'LP\')')));
    if (userPositionSize) {
      maxPosition = Math.max.apply(null, userPositionSize);
      minPosition = Math.min.apply(null, userPositionSize);
      addItemToList(conditions, (['(capitalAmount < ',maxPosition,' or privacySizeRestriction = false)'].join('')));
      addItemToList(conditions, (['(capitalAmount > ',minPosition,' or privacySizeRestriction = false)'].join('')));
      pageSize = (getObjectProperty(___arguments.context.pageData, 'rowSize'));
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    } else {
      addItemToList(conditions, (String('privacySizeRestriction = false')));
      pageSize = (getObjectProperty(___arguments.context.pageData, 'rowSize'));
      ___arguments.context.pageData['dealList'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))).setSortBy('').setPageSize(pageSize).setOffset((((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1) * pageSize))));
      ___arguments.context.pageData['countProps'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND ')))));
      ___arguments.context.pageData['pageCount'] = (Math.ceil((await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((conditions.join(' AND '))))) / pageSize));
    }
  }

  },
  /* handler:onGoLast */
  /* content */
}))

define('./pages/Messager/components/0e83e5d28c41dcf6f72b118c4c88e952/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('95b257cca481dadd4915f12b5ce32d4c', true);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/Messager/components/95e1be81ecad4fa235a24429cb5bb083/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboardProfile', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Messager/components/7cde2e1adce2ddb3553aa1f90d89c299/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('addProperty', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/Messager/components/c0091092e8fe8ec6fd45930d14a76a03/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('myDeals', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Messager/components/830a113e0b9aee0d35899d31523f45bd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('pendingProperties', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/Messager/components/af2fbb7450da4513e96a5a8d35673f84/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('publishedProperties', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/Messager/components/f008e675089e4e9bbcbb7d452aaea46a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboardFavorites', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Messager/components/bf79192992b28d5efbc7e9455a58d262/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      localStorage.removeItem('currentUser');
  await Backendless.UserService.logout();
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('welcome', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Messager/components/9428c5720e28c29a2ed862b8d348dbb8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Marketplace', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Messager/components/699a9a783da63496fbb863b85f04c43c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'myDeals')) > 0) {
    return true;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Messager/components/00cd1edace9880678fb177dfd2c869b8/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'favorites')) > 0) {
    return true;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Messager/components/dbf8ebcaea23917c8fb86afdc09262b8/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var createdChat, user, newChatId, actualChat, j, chatId, object, chatMembers, item, chatsList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function getSubscriptionsManager(mapName) { const subs = getGlobalEntitiesMap(mapName);subs.map = subs.map || {};subs.remove = subs.remove || (subId => { if (subs.map[subId]) { subs.map[subId](); delete subs.map[subId];} });subs.add = subs.add || ((subId, callback) => {    if (subs.map[subId]) { throw new Error(`Subscription with ID: "${subId}" already exists.`) }    subs.map[subId] = callback; });return subs;}

function getRTSubscriptions() { return getSubscriptionsManager('subscriptions');}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function addRTMessageListener(chatsList) {
}

/**
 * Describe this function...
 */
async function addRTdeleteMessageListener(chatsList) {
}

/**
 * Describe this function...
 */
async function addRTMessageUpdateListener(chatsList) {
}

/**
 * Describe this function...
 */
async function addRTRemoveFromBlockedListListener() {
}

/**
 * Describe this function...
 */
async function addRTChatDeleteListener(chatsList) {
}

/**
 * Describe this function...
 */
async function addRTAddToBlockListListener() {
}

/**
 * Describe this function...
 */
async function addRTUserStatusListener(chatsList) {
}


  if (await Backendless.UserService.isValidLogin()) {
    user = (await Backendless.UserService.getCurrentUser(false));
    ___arguments.context.dataModel['searchData'] = '';
    ___arguments.context.dataModel['chatsList'] = (await Backendless.Data.of('Chat').find(Backendless.DataQueryBuilder.create().setWhereClause((['members = \'',(getObjectProperty(user, 'objectId')),'\' AND groupName IS NULL'].join(''))).setRelated('members').setPageSize(100)));
    chatMembers = [];
    var j_list = (await Promise.all(((getObjectProperty(___arguments.context.dataModel, 'chatsList')).map(item => item['members'])).map(async item => {; return (item.map(item => item['objectId']));})));
    for (var j_index in j_list) {
      j = j_list[j_index];
      removeItemInList(j, (getObjectProperty(user, 'objectId')), '');
      chatMembers = (chatMembers.concat(j));
    }
    if (!(getObjectProperty(___arguments.context.dataModel, 'chatsList')).length && (getObjectProperty(___arguments.context.pageData, 'activePane')) == 'chats') {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1a1978cce5cad0b2195f373fce0e06ed', false);
    }
    await addRTUserStatusListener((getObjectProperty(___arguments.context.dataModel, 'chatsList')));
    await addRTMessageListener((getObjectProperty(___arguments.context.dataModel, 'chatsList')));
    await addRTMessageUpdateListener((getObjectProperty(___arguments.context.dataModel, 'chatsList')));

    ;(function(whereClause) {
      const callback = async object => {
          if (getObjectProperty(object, 'count')) {
        chatId = (getObjectProperty(object, 'whereClause')).slice((((getObjectProperty(object, 'whereClause')).indexOf('\'') + 1 + 1) - 1), (getObjectProperty(object, 'whereClause')).indexOf('\'') + 1 + 36);
        if (((getObjectProperty(___arguments.context.dataModel, 'chatsList')).map(item => item['objectId'])).includes(chatId)) {
          actualChat = 0;
          var j_list2 = (getObjectProperty(actualChat, 'messages'));
          for (var j_index2 in j_list2) {
            j = j_list2[j_index2];
            j['isRead'] = true;
          }
          if ((getObjectProperty(___arguments.context.pageData, 'activeChat')) == (getObjectProperty(actualChat, 'objectId')) && !(getObjectProperty(___arguments.context.pageData, 'searchData'))) {
            (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e58fa419f53bb015cd7f1a0344714254', (getObjectProperty(actualChat, 'messages')));
          }
        }
      }

      };

      const rtHandlers = Backendless.Data.of('Messages').rt();

      const options = [callback];

      if (whereClause) {
         options.unshift(whereClause);
      }

      rtHandlers.addBulkUpdateListener.apply(rtHandlers, options);

      getRTSubscriptions().add('UpdateMessageStatusOfIndividualChat', () => rtHandlers.removeBulkUpdateListener(callback));

    })('');

    ;(function(parentObjects) {
      const callback = async newChatId => {
          createdChat = (await Backendless.Data.of('Chat').findById(((getObjectProperty(newChatId, 'children'))[0]), Backendless.DataQueryBuilder.create().setRelated('members')));
      if ((getObjectProperty(createdChat, 'ownerId')) != (getObjectProperty(user, 'objectId'))) {
        if (!(getObjectProperty(createdChat, 'groupName'))) {
          addItemToList((getObjectProperty(___arguments.context.dataModel, 'chatsList')), createdChat);
        }
      }
      getRTSubscriptions().remove('checkIndividualChatUserStatus');
      getRTSubscriptions().remove('addMessageToIndividualChat');
      getRTSubscriptions().remove('updateMessageOfIndividualChat');
      getRTSubscriptions().remove('deleteIndividualChatMessage');
      getRTSubscriptions().remove('deleteIndividualChat');
      getRTSubscriptions().remove('checkAddToBlockedUserLists');
      getRTSubscriptions().remove('checkRemoveFromBlockedUserLists');
      await addRTUserStatusListener((getObjectProperty(___arguments.context.dataModel, 'chatsList')));
      await addRTMessageListener((getObjectProperty(___arguments.context.dataModel, 'chatsList')));
      await addRTMessageUpdateListener((getObjectProperty(___arguments.context.dataModel, 'chatsList')));
      await addRTdeleteMessageListener((getObjectProperty(___arguments.context.dataModel, 'chatsList')));
      await addRTChatDeleteListener((getObjectProperty(___arguments.context.dataModel, 'chatsList')));
      await addRTAddToBlockListListener();
      await addRTRemoveFromBlockedListListener();

      };

      const rtHandlers = Backendless.Data.of('Users').rt();

      const options = ['chats'];

      if (parentObjects) {
         options.push(parentObjects);
      }

      options.push(callback)

      rtHandlers.addAddRelationListener.apply(rtHandlers, options);

      getRTSubscriptions().add('AddIndividualChat', () => rtHandlers.removeAddRelationListener(callback));

    })([user]);
      await addRTdeleteMessageListener((getObjectProperty(___arguments.context.dataModel, 'chatsList')));
    await addRTChatDeleteListener((getObjectProperty(___arguments.context.dataModel, 'chatsList')));
    await addRTAddToBlockListListener();
    await addRTRemoveFromBlockedListListener();
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/Messager/components/b198e228a032d8288667f8e8f5b6ac58/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  async ['onDynamicItemsAssignment'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('dbf8ebcaea23917c8fb86afdc09262b8'), 'chatsList')) ? ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('dbf8ebcaea23917c8fb86afdc09262b8'), 'chatsList')).length > 1 ? (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('dbf8ebcaea23917c8fb86afdc09262b8'), 'chatsList')), -1, async (item) => {


 return (getObjectProperty(item, 'sortTime'));
})) : (getObjectProperty(___arguments.context.getComponentDataStoreByUid('dbf8ebcaea23917c8fb86afdc09262b8'), 'chatsList'))) : [])

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/Messager/components/699801a3d908e1af1916a248de1863b8/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
      return (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 1024)

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('dbf8ebcaea23917c8fb86afdc09262b8', false);
  removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('8c1718e18e3becfe3c98baa552281ac8')), 'chat--show', '');
  delete ___arguments.context.pageData['activeChat'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Messager/components/713c6108738e4b3d74a9c412a098bcbc/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('8c1718e18e3becfe3c98baa552281ac8'), 'groupName')) ? (getObjectProperty(___arguments.context.getComponentDataStoreByUid('8c1718e18e3becfe3c98baa552281ac8'), 'groupName')) : (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('8c1718e18e3becfe3c98baa552281ac8'), 'member')), 'username')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/NewAccountRegistration/components/f5e82f9829b85c4967caeb02bf5e6533/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, profileID;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.UserService.logout();
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d2e6b71c925888d5c81857ca99a8cbc8', true);
  try {
    if ((getObjectProperty(___arguments.context.pageData, 'password')) != (getObjectProperty(___arguments.context.pageData, 'password2'))) {
      ___arguments.context.pageData['statusMessage'] = 'Passwords do not match';
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b61615e9bf38d95e26387c362dbdd618', true);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d2e6b71c925888d5c81857ca99a8cbc8', false);
      (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('29e8b3c109185598d242c0e87513dfaa')));
    } else if ((getObjectProperty(___arguments.context.pageData, 'userType')) == null) {
      ___arguments.context.pageData['statusMessage'] = 'Form Incomplete';
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b61615e9bf38d95e26387c362dbdd618', true);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d2e6b71c925888d5c81857ca99a8cbc8', false);
      (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('29e8b3c109185598d242c0e87513dfaa')));
    } else if ((getObjectProperty(___arguments.context.pageData, 'password')).length < 8) {
      ___arguments.context.pageData['statusMessage'] = 'Password must be at least 8 characters';
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b61615e9bf38d95e26387c362dbdd618', true);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d2e6b71c925888d5c81857ca99a8cbc8', false);
      (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('29e8b3c109185598d242c0e87513dfaa')));
    } else if ((getObjectProperty(___arguments.context.pageData, 'firm')) == null) {
      ___arguments.context.pageData['statusMessage'] = 'Form Incomplete';
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b61615e9bf38d95e26387c362dbdd618', true);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d2e6b71c925888d5c81857ca99a8cbc8', false);
      (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('29e8b3c109185598d242c0e87513dfaa')));
    } else if ((getObjectProperty(___arguments.context.pageData, 'email')) == null) {
      ___arguments.context.pageData['statusMessage'] = 'Form Incomplete';
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b61615e9bf38d95e26387c362dbdd618', true);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d2e6b71c925888d5c81857ca99a8cbc8', false);
      (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('29e8b3c109185598d242c0e87513dfaa')));
    } else if ((getObjectProperty(___arguments.context.pageData, 'firstName')) == null) {
      ___arguments.context.pageData['statusMessage'] = 'Form Incomplete';
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b61615e9bf38d95e26387c362dbdd618', true);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d2e6b71c925888d5c81857ca99a8cbc8', false);
      (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('29e8b3c109185598d242c0e87513dfaa')));
    } else if ((getObjectProperty(___arguments.context.pageData, 'lastName')) == null) {
      ___arguments.context.pageData['statusMessage'] = 'Form Incomplete';
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b61615e9bf38d95e26387c362dbdd618', true);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d2e6b71c925888d5c81857ca99a8cbc8', false);
      (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('29e8b3c109185598d242c0e87513dfaa')));
    } else if ((getObjectProperty(___arguments.context.pageData, 'phone')) == null) {
      ___arguments.context.pageData['statusMessage'] = 'Form Incomplete';
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b61615e9bf38d95e26387c362dbdd618', true);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d2e6b71c925888d5c81857ca99a8cbc8', false);
      (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('29e8b3c109185598d242c0e87513dfaa')));
    } else {
      if ((getObjectProperty(___arguments.context.pageData, 'userType')) == 'Equity') {
        await Backendless.Request.post(`${Backendless.appPath}/services/RegistrationService/registerEquity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'email': (getObjectProperty(___arguments.context.pageData, 'email')),'password': (getObjectProperty(___arguments.context.pageData, 'password')),'TOU': true });
      } else if ((getObjectProperty(___arguments.context.pageData, 'userType')) == 'GP') {
        await Backendless.Request.post(`${Backendless.appPath}/services/RegistrationService/registerGP`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'email': (getObjectProperty(___arguments.context.pageData, 'email')),'password': (getObjectProperty(___arguments.context.pageData, 'password')),'TOU': true });
      } else if ((getObjectProperty(___arguments.context.pageData, 'userType')) == 'Debt') {
        await Backendless.Request.post(`${Backendless.appPath}/services/RegistrationService/registerDebt`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'email': (getObjectProperty(___arguments.context.pageData, 'email')),'password': (getObjectProperty(___arguments.context.pageData, 'password')),'TOU': true });
      }
      await Backendless.UserService.logout();
      await Backendless.UserService.login( (getObjectProperty(___arguments.context.pageData, 'email')), (getObjectProperty(___arguments.context.pageData, 'password')), true  );
      ___arguments.context.pageData['currentUser'] = (await Backendless.UserService.getCurrentUser(false));
      profileID = (await Backendless.Data.of('Profile').bulkCreate( [({ [`email`]: (getObjectProperty(___arguments.context.pageData, 'email')),[`phone`]: (getObjectProperty(___arguments.context.pageData, 'phone')),[`firstName`]: (getObjectProperty(___arguments.context.pageData, 'firstName')),[`lastName`]: (getObjectProperty(___arguments.context.pageData, 'lastName')),[`firm`]: (getObjectProperty(___arguments.context.pageData, 'firm')),[`title`]: (getObjectProperty(___arguments.context.pageData, 'title')),[`website`]: (getObjectProperty(___arguments.context.pageData, 'website')),[`location`]: (getObjectProperty(___arguments.context.pageData, 'location')),[`userType`]: (getObjectProperty(___arguments.context.pageData, 'userType')),[`positionSize`]: (getObjectProperty(___arguments.context.pageData, 'positionSize')),[`dealSize`]: (getObjectProperty(___arguments.context.pageData, 'dealSize')),[`propertyInvested`]: (getObjectProperty(___arguments.context.pageData, 'propertyInvested')) })] ));
      await Backendless.Data.of('Users').addRelation((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'profile', (['objectId = \'',profileID,'\''].join('')));
      ___arguments.context.pageData['statusMessage2'] = 'Registration complete. Information under review. You will be notified upon account activation.';
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b61615e9bf38d95e26387c362dbdd618', false);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e338346409a8bbb47345ee717d01273e', true);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('dc327433e5ae17ad7a54bc8b0d15baa5', false);
      await Backendless.Request.post(`${Backendless.appPath}/services/userStatus/status`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send();
      await new Promise(r => setTimeout(r, 4000 || 0));
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', undefined);
    }

  } catch (error) {
    ___arguments.context.pageData['statusMessage'] = error;
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b61615e9bf38d95e26387c362dbdd618', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d2e6b71c925888d5c81857ca99a8cbc8', false);
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('29e8b3c109185598d242c0e87513dfaa')));

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/NewAccountRegistration/components/9af87c887d68b60cc3c2230afaf6d69b/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['email'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewAccountRegistration/components/79a812a8d658b76887e830f96a1836be/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['firstName'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewAccountRegistration/components/54f8e7e8e1f813c25b686277ba28e6e8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['lastName'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewAccountRegistration/components/df3baf98d2c8d6538cc81448bca8e778/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['firm'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewAccountRegistration/components/e3c925c341637bd83298ca78ba8f1ba8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['phone'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewAccountRegistration/components/dff83d18f355debc09981d38c45825d8/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'userType')) {
    if ((getObjectProperty(___arguments.context.pageData, 'userType')) == 'Equity') {
      return true;
    } else if ((getObjectProperty(___arguments.context.pageData, 'userType')) == 'Debt') {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
      ___arguments.context.pageData['positionSize'] = [];

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/NewAccountRegistration/components/128667ffe546376f7aee20d80f45ad48/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'userType')) {
    if ((getObjectProperty(___arguments.context.pageData, 'userType')) == 'GP') {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
      ___arguments.context.pageData['dealSize'] = [];

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/NewAccountRegistration/components/52f86fa844088086e57b55b832230e24/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == '1') {
    ___arguments.context.pageData['userType'] = 'GP';
  }
  if (___arguments.value == '2') {
    ___arguments.context.pageData['userType'] = 'Equity';
  }
  if (___arguments.value == '3') {
    ___arguments.context.pageData['userType'] = 'Debt';
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewAccountRegistration/components/a10879485918f6d870e84da8efef3c87/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['title'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewAccountRegistration/components/c4784fb828ad88e8153c9dd833826a7b/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['location'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewAccountRegistration/components/35875844fbfa1668b9c8eac8fecf8cb8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['website'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewAccountRegistration/components/9e58241fe2d89355cb3857659ee83538/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (___arguments.value) {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'positionSize')), '300000000');
    addItemToList((getObjectProperty(___arguments.context.pageData, 'positionSize')), '100000000');
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewAccountRegistration/components/53d40fe8fb48c648e2a58093048c5e18/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (___arguments.value) {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'positionSize')), '100000000');
    addItemToList((getObjectProperty(___arguments.context.pageData, 'positionSize')), '50000000');
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewAccountRegistration/components/26aa6c5867c8f58810acb688e4a8d8a8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (___arguments.value) {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'positionSize')), '50000000');
    addItemToList((getObjectProperty(___arguments.context.pageData, 'positionSize')), '10000000');
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewAccountRegistration/components/32444e98fd762038481800da1246b0a4/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (___arguments.value) {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'positionSize')), '10000000');
    addItemToList((getObjectProperty(___arguments.context.pageData, 'positionSize')), '2000000');
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewAccountRegistration/components/a5797a1833d84767e90cd081db68e19a/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (___arguments.value) {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'positionSize')), '10000000000');
    addItemToList((getObjectProperty(___arguments.context.pageData, 'positionSize')), '500000000');
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewAccountRegistration/components/163d9a4fb2de7bce0a1e4f3824d5164f/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (___arguments.value) {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'positionSize')), '500000000');
    addItemToList((getObjectProperty(___arguments.context.pageData, 'positionSize')), '300000000');
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewAccountRegistration/components/d623f9d8b268dcde98c837bca6b87c9a/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (___arguments.value) {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'dealSize')), '10000000000');
    addItemToList((getObjectProperty(___arguments.context.pageData, 'dealSize')), '500000000');
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewAccountRegistration/components/2438a7c8c4abf99f94786f68bc980ee8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (___arguments.value) {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'dealSize')), '500000000');
    addItemToList((getObjectProperty(___arguments.context.pageData, 'dealSize')), '300000000');
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewAccountRegistration/components/8e98d9c8b4c69628e95835587a6be638/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (___arguments.value) {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'dealSize')), '300000000');
    addItemToList((getObjectProperty(___arguments.context.pageData, 'dealSize')), '100000000');
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewAccountRegistration/components/9d188a8e0a182cfa8ac8ee25baf8e188/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (___arguments.value) {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'dealSize')), '100000000');
    addItemToList((getObjectProperty(___arguments.context.pageData, 'dealSize')), '50000000');
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewAccountRegistration/components/f3313c78c88c1248103988f3be587828/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (___arguments.value) {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'dealSize')), '50000000');
    addItemToList((getObjectProperty(___arguments.context.pageData, 'dealSize')), '10000000');
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewAccountRegistration/components/57688d3da289aeef09b85b5867fc369b/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (___arguments.value) {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'dealSize')), '10000000');
    addItemToList((getObjectProperty(___arguments.context.pageData, 'dealSize')), '2000000');
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewAccountRegistration/components/fe4892a86e782018793155d5038acf28/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
      ___arguments.context.pageData['propertyInvested'] = [];

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/NewAccountRegistration/components/45d8efafee2706ae54afe008cd09b1e8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (___arguments.value) {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'propertyInvested')), 'Multifamily');
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewAccountRegistration/components/163d48d95f6d0a4b335855daafc8d6f8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (___arguments.value) {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'propertyInvested')), 'Industrial');
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewAccountRegistration/components/bd68ad48c19bc8aad16862ee80b916e8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (___arguments.value) {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'propertyInvested')), 'Mixed Use');
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewAccountRegistration/components/ac088878af1b9f487ecc617b22f80b98/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (___arguments.value) {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'propertyInvested')), 'Office');
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewAccountRegistration/components/f6488fa8e8f890a59a78d1f86c66fbe8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (___arguments.value) {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'propertyInvested')), 'Retail');
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewAccountRegistration/components/a778ddb80848d1e899457cf808e95748/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (___arguments.value) {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'propertyInvested')), 'Life Science');
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewAccountRegistration/components/2d4e9849771207c33c28befc9fbd2b08/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (___arguments.value) {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'propertyInvested')), 'Medical Office');
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewAccountRegistration/components/f02653d183b70c31c7fccc9805df49b8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (___arguments.value) {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'propertyInvested')), 'Hotel & Hospitality');
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewAccountRegistration/components/8d280f68fe73611ae1b8c9a91598d918/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (___arguments.value) {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'propertyInvested')), 'Self Storage');
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewAccountRegistration/components/c72bc4f5f21bdd9fd216f123e018f278/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (___arguments.value) {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'propertyInvested')), 'Land');
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewAccountRegistration/components/2f5f7738709bb4ff90f8cab89e5bd0f8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (___arguments.value) {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'propertyInvested')), 'Single Family');
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewAccountRegistration/components/6f6bf70d8b875b98d678bbd842eb1bc8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (___arguments.value) {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'propertyInvested')), 'R&D');
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewAccountRegistration/components/4f58f0086f34e3dabe8832987e21a4aa/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (___arguments.value) {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'propertyInvested')), 'Datacenter');
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewAccountRegistration/components/a2c3d82866c3e3b8ee4bc7d828289548/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (___arguments.value) {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'propertyInvested')), 'Other');
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/addDeal/components/0e83e5d28c41dcf6f72b118c4c88e952/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('95b257cca481dadd4915f12b5ce32d4c', true);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/addDeal/components/fb1fbdabe28d51188b462bd87dce72dd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('fb1fbdabe28d51188b462bd87dce72dd')).slice(-1)[0] == 'active') {
    return (((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('fb1fbdabe28d51188b462bd87dce72dd')).pop());
  } else {
    addItemToList(((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('fb1fbdabe28d51188b462bd87dce72dd')), 'active');
  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/addDeal/components/cd697b1ab705f70131ac0511b6c8548a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'statusMessage'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/addDeal/components/95e1be81ecad4fa235a24429cb5bb083/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboardProfile', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/addDeal/components/2ba47cc65164653393ee688a4e748d91/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['shortDescription'] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'shortDescription')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'shortDescription'));
    }
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/addDeal/components/6914dc19a6b78b1bfbab2d5618e2e013/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['propertyType'] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'propertyType')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'propertyType'));
    }
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/addDeal/components/6d5861d822050f295385336e0f3b12c2/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['investmentStrategy'] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'investmentStrategy')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'investmentStrategy'));
    }
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/addDeal/components/f69ddf3b6416ceb8ec530cab297fc6ac/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value > 0 || !___arguments.value) {
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['buildings'] = ___arguments.value;
  }

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'buildings')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'buildings'));
    }
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/addDeal/components/ca66f1bc5fd89244ae8116ba094ac0fb/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value > 0 || !___arguments.value) {
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['rentableArea'] = ___arguments.value;
  }

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'rentableArea')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'rentableArea'));
    }
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/addDeal/components/4209c7bba5c2e6e92ec98c7786918c82/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value > 0 || !___arguments.value) {
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['landArea'] = ___arguments.value;
  }

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'landArea')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'landArea'));
    }
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/addDeal/components/83c4bce5c057cb2230c803f1da1a8f79/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['currentTab'] = 'General';

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentTab')) == 'General') {
    return ['dashboard__tab', 'active'];
  }

  return ['dashboard__tab']

  },
  /* handler:onClassListAssignment */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
      ___arguments.context.pageData['currentTab'] = 'General';

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./pages/addDeal/components/01cb9744d4687d7b8292033ed959204b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['currentTab'] = 'Gallery';

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentTab')) == 'Gallery') {
    return ['dashboard__tab', 'active'];
  }

  return ['dashboard__tab']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/addDeal/components/ab1b6618e25bbe4f7d889759da2dbc0f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['currentTab'] = 'Financials';

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentTab')) == 'Financials') {
    return ['dashboard__tab', 'active'];
  }

  return ['dashboard__tab']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/addDeal/components/ba8c078b635be937381abb0603c456ce/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['currentTab'] = 'Privacy';

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentTab')) == 'Privacy') {
    return ['dashboard__tab', 'active'];
  }

  return ['dashboard__tab']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/addDeal/components/64d4ebce209996652ad7324180e28744/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentTab')) == 'Privacy') {
    return false;
  } else {
    return true;
  }

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentTab')) == 'General') {
    ___arguments.context.pageData['currentTab'] = 'Financials';
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentTab')) == 'Financials') {
    ___arguments.context.pageData['currentTab'] = 'Gallery';
  } else {
    ___arguments.context.pageData['currentTab'] = 'Privacy';
  }
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('448afaeb48d8dae4b38393fdf91109a8')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/addDeal/components/6b4cd39cd4bc4b1b45564cc51d96d606/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentTab')) == 'Privacy') {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, createdDeal, createdDealId, j, item, newHighlights, newObject, newUses, newSources;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3bd1aa0db9ba3578c74e7cb81a0ba607', true);
  if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('6b4cd39cd4bc4b1b45564cc51d96d606')).includes('inactive')) {
    return ;
  } else {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('6b4cd39cd4bc4b1b45564cc51d96d606')), 'inactive');
    try {
      if (!(getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'dealName'))) {
        ___arguments.context.pageData['statusMessage'] = 'Field \'title\' is required!';
        (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3bd1aa0db9ba3578c74e7cb81a0ba607', false);
        return ;
      } else {
        ___arguments.context.pageData['statusMessage'] = '';
      }
      if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'images')) {
        if (!!(getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'images')).length) {
          if (!(await asyncListFilter((getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'images')), async (item) => {


           return ((getObjectProperty(item, 'isMain')) == true);
          })).length) {
            ((getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'images'))[0])['isMain'] = true;
          }
        } else {
          (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['images'] = null;
        }
      }
      if (!!(await asyncListFilter((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'parsedSources')), async (item) => {


       return (!!(Object.keys(item)).length);
      })).length) {
        newSources = (await Promise.all((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'parsedSources')).map(async item => {  if ((getObjectProperty(item, 'title')) && (getObjectProperty(item, 'value'))) {
            newObject = ({  });
            newObject[(getObjectProperty(item, 'title'))] = (getObjectProperty(item, 'value'));
          }
        ; return newObject;})));
        (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['sources'] = (await asyncListFilter(newSources, async (item) => {


         return item;
        }));
      }
      if (!!(await asyncListFilter((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'parsedUses')), async (item) => {


       return (!!(Object.keys(item)).length);
      })).length) {
        newUses = (await Promise.all((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'parsedUses')).map(async item => {  if ((getObjectProperty(item, 'title')) && (getObjectProperty(item, 'value'))) {
            newObject = ({  });
            newObject[(getObjectProperty(item, 'title'))] = (getObjectProperty(item, 'value'));
          }
        ; return newObject;})));
        (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['uses'] = (await asyncListFilter(newUses, async (item) => {


         return item;
        }));
      }
      if (!!(await asyncListFilter((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'parsedHighlights')), async (item) => {


       return (!!(Object.keys(item)).length);
      })).length) {
        newHighlights = (await Promise.all((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'parsedHighlights')).map(async item => {  if (getObjectProperty(item, 'value')) {
            newObject = (getObjectProperty(item, 'value'));
          }
        ; return newObject;})));
        (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['highlights'] = (await asyncListFilter(newHighlights, async (item) => {


         return item;
        }));
      }
      if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'imagesToDelete')) {
        var j_list = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'imagesToDelete'));
        for (var j_index in j_list) {
          j = j_list[j_index];
          await Backendless.Files.remove(encodePath(j));
        }
      }
      if (getObjectProperty(___arguments.context.pageData, 'dealId')) {
        await Backendless.Data.of('Deal').bulkUpdate( (['objectId=\'',(getObjectProperty(___arguments.context.pageData, 'dealId')),'\''].join('')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) );
        if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'userProfile')), 'objectId')) {
          await Backendless.Data.of('Deal').setRelation((getObjectProperty(___arguments.context.pageData, 'dealId')), 'profile', (['objectId=\'',(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'userProfile')), 'objectId')),'\''].join('')));
        }
        (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboard', ({ [`success`]: 'Property updated successfully!' }));
      } else {
        createdDealId = (await Backendless.Data.of('Deal').bulkCreate( [(getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))] ));
        createdDeal = String(createdDealId);
      }
      if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'userProfile')), 'objectId')) {
        await Backendless.Data.of('Deal').setRelation(createdDeal, 'profile', (['objectId=\'',(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'userProfile')), 'objectId')),'\''].join('')));
        await Backendless.Data.of('Profile').setRelation((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'userProfile')), 'objectId')), 'deal', (['objectId=\'',createdDeal,'\''].join('')));
      }
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('myDeals', ({ [`success`]: 'Thanks for Submitting Property!' }));

    } catch (error) {
      ___arguments.context.pageData['statusMessage'] = error;

    } finally {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboard', undefined);

    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/addDeal/components/94e6207ce7cc082c4a37fc97fb557ec4/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentTab')) == 'General') {
    return 'true';
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/addDeal/components/5d094f572a9b37f805f5477721015168/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
      if ((___arguments.context.pageData['currentTab']) == 'General') {
    return ['MuiButtonBase-root MuiButton-root bl-button MuiButton-text bl-button--text dashboard__submit btn', 'btn-disabled'];
  }

  return ['MuiButtonBase-root MuiButton-root bl-button MuiButton-text bl-button--text dashboard__submit btn']

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentTab')) == 'Agent') {
    ___arguments.context.pageData['currentTab'] = 'Gallery';
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentTab')) == 'Gallery') {
    ___arguments.context.pageData['currentTab'] = 'Financials';
  } else if ((getObjectProperty(___arguments.context.pageData, 'currentTab')) == 'Financials') {
    ___arguments.context.pageData['currentTab'] = 'General';
  }
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('448afaeb48d8dae4b38393fdf91109a8')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/addDeal/components/4fa22494cd13a1bfed3bc52af74a44ac/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentTab')) == 'Gallery') {
    return 'true';
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/addDeal/components/f0047ff3aa257a586a2b26168d4159b9/bundle.js', [], () => ({
  /* content */
  /* handler:onUploadSuccess */
  async ['onUploadSuccess'](___arguments) {
    var newImages, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  newImages = (await Promise.all(___arguments.uploadedFiles.map(async item => {; return ({ 'file': item,'isMain': false });})));
  if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'images')) {
    addItemToList((getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'images')), newImages);
  } else {
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['images'] = newImages;
  }
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('f0047ff3aa257a586a2b26168d4159b9');

  },
  /* handler:onUploadSuccess */
  /* handler:onDirectoryAssignment */
  ['onDirectoryAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    return (String('/dealfiles/') + String(getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'dealName')));
  }

  },
  /* handler:onDirectoryAssignment */
  /* handler:onFileNameAssignment */
  onFileNameAssignment(___arguments) {
      return (String((new Date()).toTimeString()) + String(___arguments.file['name']))

  },
  /* handler:onFileNameAssignment */
  /* content */
}))

define('./pages/addDeal/components/5c431354db919f8f9f133f136d8ed2db/bundle.js', [], () => ({
  /* content */
  /* handler:onUploadSuccess */
  async ['onUploadSuccess'](___arguments) {
    var newAttachments, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  newAttachments = (await Promise.all(___arguments.uploadedFiles.map(async item => {; return ({ 'file': item });})));
  if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'attachments')) {
    addItemToList((getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'attachments')), newAttachments);
  } else {
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['attachments'] = newAttachments;
  }
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('5c431354db919f8f9f133f136d8ed2db');

  },
  /* handler:onUploadSuccess */
  /* handler:onDirectoryAssignment */
  ['onDirectoryAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    return (['/dealfiles/',(getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'dealName')),(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'userProfile')), 'objectId'))].join(''));
  }

  },
  /* handler:onDirectoryAssignment */
  /* handler:onFileNameAssignment */
  onFileNameAssignment(___arguments) {
      return (String((new Date()).valueOf()) + String(___arguments.file['name']))

  },
  /* handler:onFileNameAssignment */
  /* content */
}))

define('./pages/addDeal/components/232513cc82e69549fa77c13b74dcc6e5/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'images'));
  }

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/addDeal/components/4c0f043386d5b845e6f474143c19b208/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'attachments'));
  }

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/addDeal/components/6c9284fd8fb75b2521f5c20e358b7871/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var newList, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  newList = (await asyncListFilter((getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'images')), async (item) => {


   return (item != ___arguments.context.getComponentDataStoreByUid('93ded84ee5c708e5358df107d1cc0498'));
  }));
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'imagesToDelete')) {
    addItemToList((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'imagesToDelete')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('93ded84ee5c708e5358df107d1cc0498'), 'file')));
  } else {
    ___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237')['imagesToDelete'] = [(getObjectProperty(___arguments.context.getComponentDataStoreByUid('93ded84ee5c708e5358df107d1cc0498'), 'file'))];
  }
  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['images'] = newList;
  if (!(getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'images')).length) {
    (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('f0047ff3aa257a586a2b26168d4159b9');
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/addDeal/components/768ab912b58183ace71401b7af0569e7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var newList, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}


  newList = (await asyncListFilter((getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'attachments')), async (item) => {


   return (item != ___arguments.context.getComponentDataStoreByUid('1973ea3d59a74277ce06b03b61be15c3'));
  }));
  await Backendless.Files.remove(encodePath((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1973ea3d59a74277ce06b03b61be15c3'), 'file'))));
  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['attachments'] = newList;
  if (!(getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'attachments')).length) {
    (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('5c431354db919f8f9f133f136d8ed2db');
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/addDeal/components/40a29092e9e8e998f8771bfe46438964/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var fileName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}


  fileName = subsequenceFromStartLast((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1973ea3d59a74277ce06b03b61be15c3'), 'file')), (((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1973ea3d59a74277ce06b03b61be15c3'), 'file')).lastIndexOf('/') + 1 + 1) - 1));

  return fileName

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/addDeal/components/2bc56f52e811726eea2d7aaf79ad5449/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentTab')) == 'Financials') {
    return 'true';
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/addDeal/components/d8d880884a08ea6fa40fefa9c67ff753/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentTab')) == 'Privacy') {
    return 'true';
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/addDeal/components/de2e95a9e1f7c84bf756e2ec250ec2e8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  addItemToList((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'parsedSources')), ({  }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/addDeal/components/91e7ec73b678e1fedfa8ed6f1ff79136/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  ___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237')['parsedSources'] = (await asyncListFilter((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'parsedSources')), async (item) => {


   return (item != ___arguments.context.getComponentDataStoreByUid('90be93b7eff41d0b4e1828f2fe3daa73'));
  }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/addDeal/components/00590b9bbbc8aed595b9a4e529d2f71e/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('90be93b7eff41d0b4e1828f2fe3daa73')['title'] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('90be93b7eff41d0b4e1828f2fe3daa73'), 'title'))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/addDeal/components/ed058c1d9d01fe4840a3a5aa584a5095/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('90be93b7eff41d0b4e1828f2fe3daa73')['value'] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('90be93b7eff41d0b4e1828f2fe3daa73'), 'value'))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/addDeal/components/e572d60cf9687e2a8c9cb8dc27630712/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'address')) {
    return true;
  }

  return false

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/addDeal/components/b1abddf24d1dde2de0ccae3f3c36dbcd/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  onBeforeMount(___arguments) {
      ___arguments.context.pageData['currentTab'] = 'General';

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./pages/addDeal/components/c0091092e8fe8ec6fd45930d14a76a03/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('myDeals', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/addDeal/components/bb690b94bf9a7075334b44a17a591237/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var item, key, emptyProps;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function listsRepeat(value, n) {
  var array = [];
  for (var i = 0; i < n; i++) {
    array[i] = value;
  }
  return array;
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if (getObjectProperty(___arguments.context.pageData, 'dealId')) {
    ___arguments.context.pageData['deal'] = (await Backendless.Data.of('Deal').findById((getObjectProperty(___arguments.context.pageData, 'dealId')), Backendless.DataQueryBuilder.create().excludeProperties(['objectId', 'dealId', 'updated', 'created', 'ownerId']).setRelated('profile')));
  }
  ___arguments.context.dataModel['checkedTerms'] = false;
  ___arguments.context.dataModel['newDeal'] = ({  });
  ___arguments.context.dataModel['sources'] = (listsRepeat(({  }), 1));
  ___arguments.context.dataModel['uses'] = (listsRepeat(({  }), 1));
  ___arguments.context.dataModel['highlights'] = (listsRepeat(({  }), 1));
  ___arguments.context.dataModel['profileId'] = ({  });
  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    emptyProps = (await asyncListFilter((Object.keys((getObjectProperty(___arguments.context.pageData, 'deal')))), async (item) => {
       if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), item)) != null) {
        (getObjectProperty(___arguments.context.dataModel, 'newDeal'))[item] = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), item));
      }


     return ;
    }));
    if (getObjectProperty((getObjectProperty(___arguments.context.dataModel, 'newDeal')), 'location')) {
      if ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f2115f8de4f6b99b63e1e9f17723ebe2')) {
        (function (componentUid, lat, lng) { ___arguments.context.getComponentByUid(componentUid).center = { lat, lng } })('f2115f8de4f6b99b63e1e9f17723ebe2', (getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.dataModel, 'newDeal')), 'location')), 'lat')), (getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.dataModel, 'newDeal')), 'location')), 'lng')));
        (function (componentUid, points) { ___arguments.context.getComponentByUid(componentUid).points = points })('f2115f8de4f6b99b63e1e9f17723ebe2', [(getObjectProperty((getObjectProperty(___arguments.context.dataModel, 'newDeal')), 'location'))]);
      }
    }
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'profile')) {
      ___arguments.context.dataModel['profileId'] = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'profile'));
    }
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'sources')) {
      ___arguments.context.dataModel['sources'] = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'sources'));
    }
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'uses')) {
      ___arguments.context.dataModel['uses'] = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'uses'));
    }
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'highlights')) {
      ___arguments.context.dataModel['highlights'] = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'highlights'));
    }
  }
  if (!!(await asyncListFilter((getObjectProperty(___arguments.context.dataModel, 'sources')), async (item) => {


   return (!!(Object.keys(item)).length);
  })).length) {
    ___arguments.context.dataModel['parsedSources'] = (await Promise.all((getObjectProperty(___arguments.context.dataModel, 'sources')).map(async item => {  key = (Object.keys(item))[0];
    ; return ({ [`title`]: key,[`value`]: (getObjectProperty(item, key)) });})));
  } else {
    ___arguments.context.dataModel['parsedSources'] = (getObjectProperty(___arguments.context.dataModel, 'sources'));
  }
  if (!!(await asyncListFilter((getObjectProperty(___arguments.context.dataModel, 'uses')), async (item) => {


   return (!!(Object.keys(item)).length);
  })).length) {
    ___arguments.context.dataModel['parsedUses'] = (await Promise.all((getObjectProperty(___arguments.context.dataModel, 'uses')).map(async item => {  key = (Object.keys(item))[0];
    ; return ({ [`title`]: key,[`value`]: (getObjectProperty(item, key)) });})));
  } else {
    ___arguments.context.dataModel['parsedUses'] = (getObjectProperty(___arguments.context.dataModel, 'uses'));
  }
  if (!!(await asyncListFilter((getObjectProperty(___arguments.context.dataModel, 'highlights')), async (item) => {


   return (!!(Object.keys(item)).length);
  })).length) {
    ___arguments.context.dataModel['parsedHighlights'] = (await Promise.all((getObjectProperty(___arguments.context.dataModel, 'highlights')).map(async item => {  key = (Object.keys(item))[0];
    ; return ({ [`value`]: item });})));
  } else {
    ___arguments.context.dataModel['parsedHighlights'] = (getObjectProperty(___arguments.context.dataModel, 'highlights'));
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/addDeal/components/f885c82c9fafe9acd419d1cb8337fa44/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('myProperties', null);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/addDeal/components/f008e675089e4e9bbcbb7d452aaea46a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboardFavorites', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/addDeal/components/862f4d5a4404019bd47c62aa694248ad/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || !result.hasOwnProperty(propsNamesList[i])) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('myProperties', ({ 'searchInput': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('9a7b440bb2e32ae6007328b8bf079add'), 'searchInput')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/addDeal/components/c213b6d263d62fdb2342ea7a137fe655/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboard', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/addDeal/components/bf79192992b28d5efbc7e9455a58d262/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      localStorage.removeItem('currentUser');
  await Backendless.UserService.logout();
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/addDeal/components/5fac19aab541250884f33eeea2b22878/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('dashboard', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/addDeal/components/151727c1f012be6688a2f9e10c062f12/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })((___arguments.context.getComponentDataStoreByUid('4703a913d8776a487fa652478ea3d92b')['url']), null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/addDeal/components/8b55cd2fb77ee76469c30a63bd77baf7/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'address')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'address'));
    }
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/addDeal/components/141543396ee8c9a9c55e5f9eb9faba95/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('93ded84ee5c708e5358df107d1cc0498'), 'isMain')) {
    ___arguments.context.getComponentDataStoreByUid('93ded84ee5c708e5358df107d1cc0498')['isMain'] = false;
  } else {
    var j_list = (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'images'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      j['isMain'] = false;
    }
    console.log(getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'images'));
    ___arguments.context.getComponentDataStoreByUid('93ded84ee5c708e5358df107d1cc0498')['isMain'] = true;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('93ded84ee5c708e5358df107d1cc0498'), 'isMain')) {
    return ['gallery__image-icon gallery__image-star', 'red'];
  }

  return ['gallery__image-icon gallery__image-star']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/addDeal/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(await Backendless.UserService.isValidLogin())) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('welcome', undefined);
  }
  ___arguments.context.pageData['currentUser'] = ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('currentUser'));
  ___arguments.context.pageData['currentTab'] = 'General';
  ___arguments.context.pageData['userProfile'] = ((await Backendless.Data.of('Users').loadRelations((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('profile').setPageSize(1).setOffset(0)))[0]);
  ___arguments.context.pageData['profilePicture'] = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'userProfile')), 'profilePicture'));
  ___arguments.context.pageData['statusMessage'] = '';

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/addDeal/components/6f68b0584658c4b7e218f5286ab7e398/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['dealName'] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'dealName')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'dealName'));
    }
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/addDeal/components/b0886028a91dde34f2f14257381f306b/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['longdescription'] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'longdescription')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'longdescription'));
    }
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/addDeal/components/396cc5a89ac8516a9c98d13898d382d8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value > 0 || !___arguments.value) {
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['parking'] = ___arguments.value;
  }

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'parking')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'parking'));
    }
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/addDeal/components/6228c89874b6546fe5189788161800bf/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'capitalType')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'capitalType'));
    }
  }

  },
  /* handler:onValueAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['capitalType'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/addDeal/components/ea54a90868284948e83865c63ccf8b0d/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['capitalAmount'] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'capitalAmount')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'capitalAmount'));
    }
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/addDeal/components/e8da4b58a14902d8cfd8ad886761bb48/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'capitalDetail')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'capitalDetail'));
    }
  }

  },
  /* handler:onValueAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['capitalDetail'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/addDeal/components/59f71d1a8f7467edc7c28bf977983ded/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'firm')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'firm'));
    }
  }

  },
  /* handler:onValueAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['firm'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/addDeal/components/a2ef7498824130085008de68cd29aee8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['occupancy'] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'occupancy')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'occupancy'));
    }
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/addDeal/components/a7beedc537ca4adc3d688e2eb5a841c8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value > 0 || !___arguments.value) {
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['totalSources'] = ___arguments.value;
  }

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'totalSources')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'totalSources'));
    }
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/addDeal/components/a148a3187eadeb237948e1b6ad036741/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('32c86aa1c4ded9a8873876789528b383')['title'] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('32c86aa1c4ded9a8873876789528b383'), 'title'))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/addDeal/components/3b087fe889de45f5a798cc4836133888/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('32c86aa1c4ded9a8873876789528b383')['value'] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('32c86aa1c4ded9a8873876789528b383'), 'value'))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/addDeal/components/94f3d788dda89058cb1505f8ddfd3268/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  ___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237')['parsedUses'] = (await asyncListFilter((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'parsedUses')), async (item) => {


   return (item != ___arguments.context.getComponentDataStoreByUid('32c86aa1c4ded9a8873876789528b383'));
  }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/addDeal/components/d51816d8f0c86cd8482810684f2ab134/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  addItemToList((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'parsedUses')), ({  }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/addDeal/components/b287fa9895c89c88f12873741be8cfa7/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value > 0 || !___arguments.value) {
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['totalUses'] = ___arguments.value;
  }

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'totalUses')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'totalUses'));
    }
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/addDeal/components/02282718eee89a26e16c0b8de57870da/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['walt'] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'walt')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'walt'));
    }
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/addDeal/components/a998ee1f779c823b0d1b99d803543fd4/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['IRR'] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'IRR')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'IRR'));
    }
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/addDeal/components/002858f85e5f55a1aaa8f858bb48fd8d/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['CoC'] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'CoC')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'CoC'));
    }
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/addDeal/components/01ac134a7f68dad8ddb815dcc4183503/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Marketplace', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/addDeal/components/989cb3e82018129870a994ce3c08b808/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'city')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'city'));
    }
  }

  },
  /* handler:onValueAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['city'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/addDeal/components/4998dd58ac8876144f4e3c730588d1e8/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'state')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'state'));
    }
  }

  },
  /* handler:onValueAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['state'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/addDeal/components/4095c3ce6b48c29aeb6ec43df708b068/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'address')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'address'));
    }
  }

  },
  /* handler:onValueAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['address'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/addDeal/components/f8f893381f180958e018a3f227286f48/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value > 0 || !___arguments.value) {
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['units'] = ___arguments.value;
  }

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'units')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'units'));
    }
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/addDeal/components/5cbc611d479d284bd44ec882e4b769ce/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['privacyViewer'] = ___arguments.checkedValue;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/addDeal/components/45db64192808cc854aa6c88574984b38/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'privacyViewer')) {
    if ((getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'privacyViewer')) == 'Equity') {
      return true;
    } else if ((getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'privacyViewer')) == 'LP') {
      return true;
    } else if ((getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'privacyViewer')) == 'Debt') {
      return true;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/addDeal/components/c178f7034a6a2db8fc84e6888f7e1661/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'zoning')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'zoning'));
    }
  }

  },
  /* handler:onValueAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['zoning'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/addDeal/components/a2f20a4b690d87badf0b14e8f0452598/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value > 0 || !___arguments.value) {
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['cFAR'] = ___arguments.value;
  }

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'cFAR')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'cFAR'));
    }
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/addDeal/components/f7d8c9d91c55248597c667a87d08ecd8/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'bFAR')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'bFAR'));
    }
  }

  },
  /* handler:onValueAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value > 0 || !___arguments.value) {
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['bFAR'] = ___arguments.value;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/addDeal/components/420be2b813e2cc98a2584791b17f0528/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['exitCap'] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'exitCap')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'exitCap'));
    }
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/addDeal/components/c38b6d787a084406af187d2ce52dde28/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'purchasePrice')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'purchasePrice'));
    }
  }

  },
  /* handler:onValueAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['purchasePrice'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/addDeal/components/8cf30a913e4877885cf8cfb885cf9504/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'purchasePSF')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'purchasePSF'));
    }
  }

  },
  /* handler:onValueAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['purchasePSF'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/addDeal/components/e4688258d9fb9018e682d6a413989267/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'rNOI')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'rNOI'));
    }
  }

  },
  /* handler:onValueAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['rNOI'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/addDeal/components/3103d0c3d688503182688dc82ec89636/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'terminalValue')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'terminalValue'));
    }
  }

  },
  /* handler:onValueAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['terminalValue'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/addDeal/components/3989eae87a18b8a85032d2413148fac8/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'exitPSF')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'exitPSF'));
    }
  }

  },
  /* handler:onValueAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['exitPSF'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/addDeal/components/98b8aca8902b77b86408d478c4568e33/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['hold'] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'hold')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'hold'));
    }
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/addDeal/components/6ce8795872289c2880b89ef8789b79d8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  addItemToList((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'parsedHighlights')), ({  }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/addDeal/components/46c8a0f87c180d65aa2d9a9846381daf/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('78da699816451248c938ff3a2fb55018')['value'] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('78da699816451248c938ff3a2fb55018'), 'value'))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/addDeal/components/0028c47d2408a421e1d5013ed22832aa/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  ___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237')['parsedHighlights'] = (await asyncListFilter((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'parsedHighlights')), async (item) => {


   return (item != ___arguments.context.getComponentDataStoreByUid('78da699816451248c938ff3a2fb55018'));
  }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/addDeal/components/dc83b9e7d6b477886f85e9c9db67eee8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value == 'TRUE') {
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['privacySizeRestriction'] = true;
  }
  if (___arguments.value == 'FALSE') {
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal'))['privacySizeRestriction'] = false;
  }

  },
  /* handler:onChange */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'privacyViewer')) {
    if ((getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'privacyViewer')) == 'Equity') {
      return true;
    } else if ((getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'privacyViewer')) == 'LP') {
      return true;
    } else if ((getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'newDeal')), 'privacyViewer')) == 'Debt') {
      return true;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/addDeal/components/90be93b7eff41d0b4e1828f2fe3daa73/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('00590b9bbbc8aed595b9a4e529d2f71e')), 'el'))['maxLength'] = 250;
  (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('ed058c1d9d01fe4840a3a5aa584a5095')), 'el'))['maxLength'] = 250;

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/addDeal/components/32c86aa1c4ded9a8873876789528b383/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a148a3187eadeb237948e1b6ad036741')), 'el'))['maxLength'] = 250;
  (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('3b087fe889de45f5a798cc4836133888')), 'el'))['maxLength'] = 250;

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/addDeal/components/78da699816451248c938ff3a2fb55018/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('46c8a0f87c180d65aa2d9a9846381daf')), 'el'))['maxLength'] = 250;

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/checkoutSuccess/components/bcd73a5120c2043d378fd7a5708e938c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('myProperties', ({ 'success': 'Thank you! Payment is success.' }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/checkoutSuccess/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async onEnter(___arguments) {
      await Backendless.Data.of('Property').bulkUpdate( (['objectId=\'',(___arguments.context.pageData['propertyId']),'\''].join('')), ({ 'status': 'Published' }) );

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/dashboard/components/0e83e5d28c41dcf6f72b118c4c88e952/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('95b257cca481dadd4915f12b5ce32d4c', true);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboard/components/95e1be81ecad4fa235a24429cb5bb083/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboardProfile', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboard/components/7cde2e1adce2ddb3553aa1f90d89c299/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('addProperty', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboard/components/862f4d5a4404019bd47c62aa694248ad/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || !result.hasOwnProperty(propsNamesList[i])) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('myProperties', ({ 'searchInput': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('4d3d9c0cf319fc47552dd285f3f37564'), 'searchInput')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboard/components/c0091092e8fe8ec6fd45930d14a76a03/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('myDeals', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboard/components/830a113e0b9aee0d35899d31523f45bd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('pendingProperties', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboard/components/af2fbb7450da4513e96a5a8d35673f84/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('publishedProperties', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboard/components/9b141961fb89f4a728890386a6e61467/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['currentUser'] = ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('currentUser'));
  ___arguments.context.pageData['favorites'] = (getObjectProperty(((await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId')),'\''].join(''))).setProperties(['Count(favoriteDeals) as objectCount']).setPageSize(1)))[0]), 'objectCount'));
  ___arguments.context.pageData['myDeals'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['ownerId=\'',(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId')),'\''].join('')))));
  ___arguments.context.pageData['pendingDeals'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['ownerId=\'',(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId')),'\' and (isLive=false or isApproved=false)'].join('')))));
  ___arguments.context.pageData['publishedDeals'] = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['ownerId=\'',(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId')),'\' and isLive=true and isApproved=true'].join('')))));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/dashboard/components/e1297976e7d829bae07abe03940294bb/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'myDeals'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dashboard/components/ae27391b4c134baae9c826192b45688f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'publishedDeals'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dashboard/components/301268c7c194d1cef671c2e46ec6a9e4/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'pendingDeals'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dashboard/components/257b1e93b9e7eab39803f0bbb4ec398a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  onContentAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || !result.hasOwnProperty(propsNamesList[i])) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'favorites'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dashboard/components/f008e675089e4e9bbcbb7d452aaea46a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboardFavorites', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboard/components/bf79192992b28d5efbc7e9455a58d262/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      localStorage.removeItem('currentUser');
  await Backendless.UserService.logout();
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboard/components/9428c5720e28c29a2ed862b8d348dbb8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Marketplace', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboard/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      if (!(await Backendless.UserService.isValidLogin())) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('welcome', undefined);
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/dashboard/components/dce9b2567b82fe46a4b9bda14f396a64/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('myDeals', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboard/components/64f67c9cff9acb11e32aa5f17f770766/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('myDeals', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboard/components/8d0200717d3ce64c9dd5a0b8037bf594/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('myDeals', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboard/components/4c75b39a2f847ffafcac471d6368cb11/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboardFavorites', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboard/components/d1a89978c0c139710e384b3f09aa4c66/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var objectCount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  objectCount = (await Backendless.Data.of('Deal').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['ownerId=\'',(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId')),'\''].join('')))));
  if (___arguments.value < objectCount) {
    ___arguments.context.pageData['dealListAll'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((['ownerId=\'',(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId')),'\''].join(''))).setPageSize((Number(___arguments.value)))));
    ___arguments.context.pageData['dealList'] = (getObjectProperty(___arguments.context.pageData, 'dealListAll'));
  } else {
    if (objectCount) {
      ___arguments.context.pageData['dealListAll'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((['ownerId=\'',(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId')),'\''].join(''))).setPageSize(objectCount)));
      ___arguments.context.pageData['dealList'] = (getObjectProperty(___arguments.context.pageData, 'dealListAll'));
    }
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/dashboard/components/a6b8803ddad6b66c9de35d2baf0e6238/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('8fa8e624008b6151ae69a4918ebf64d8'), 'isLive')) == true) {
    return (['Expires in ',Math.round(((((((getObjectProperty(___arguments.context.getComponentDataStoreByUid('8fa8e624008b6151ae69a4918ebf64d8'), 'dateExpire')).valueOf()) - ((new Date()).valueOf())) / 1000) / 60) / 60) / 24),' ','days'].join(''));
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('8fa8e624008b6151ae69a4918ebf64d8'), 'isApproved')) == true && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('8fa8e624008b6151ae69a4918ebf64d8'), 'isLive')) == false) {
    return (String('Expired'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dashboard/components/6e9a197a9158f2388d78b3c893c8538e/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'currentUser')) {
    ___arguments.context.pageData['dealListAll'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((['ownerId=\'',(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId')),'\''].join(''))).setPageSize(10)));
    ___arguments.context.pageData['dealList'] = (getObjectProperty(___arguments.context.pageData, 'dealListAll'));
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/dashboard/components/8fa8e624008b6151ae69a4918ebf64d8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('myDeals', ___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboard/components/b858e4fccb6c4a3816b8242836dc9ac7/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'currentUser')) {
    ___arguments.context.pageData['favoriteListAll'] = (await Backendless.Data.of('Users').loadRelations((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('favoriteDeals').setPageSize(10).setOffset(0)));
    ___arguments.context.pageData['favoriteList'] = (getObjectProperty(___arguments.context.pageData, 'favoriteListAll'));
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/dashboard/components/699a9a783da63496fbb863b85f04c43c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'myDeals')) > 0) {
    return true;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/dashboard/components/00cd1edace9880678fb177dfd2c869b8/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'favorites')) > 0) {
    return true;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/dashboard/components/991840c120e8c5e8f0884d085f182e3a/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var objectCount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  objectCount = (getObjectProperty(___arguments.context.pageData, 'favorites'));
  if (___arguments.value < objectCount) {
    ___arguments.context.pageData['favoriteListAll'] = (await Backendless.Data.of('Users').loadRelations((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('favoriteDeals').setPageSize((Number(___arguments.value))).setOffset(0)));
    ___arguments.context.pageData['favoriteList'] = (getObjectProperty(___arguments.context.pageData, 'favoriteListAll'));
  } else {
    if (objectCount) {
      ___arguments.context.pageData['favoriteListAll'] = (await Backendless.Data.of('Users').loadRelations((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('favoriteDeals').setPageSize(objectCount).setOffset(0)));
      ___arguments.context.pageData['favoriteList'] = (getObjectProperty(___arguments.context.pageData, 'favoriteListAll'));
    }
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/dashboard/components/4a865f886d717a760907f7a7a2412773/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboardFavorites', ___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboard/components/9ff8efa8a038f8b5cd48bd1817f88f53/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'publishedDeals')) > 0) {
    return true;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/dashboard/components/b61d9f1444c9fc787bc8fad8be980e54/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'favorites')) > 0) {
    return true;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/dashboardBilling/components/4e8adbe6c134efdab161767c10054a23/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('4e8adbe6c134efdab161767c10054a23')).slice(-1)[0] == 'active') {
    return (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('4e8adbe6c134efdab161767c10054a23')).pop() && ((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('3d7248f69f01b78214380dc415639fd8')).pop());
  } else {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('4e8adbe6c134efdab161767c10054a23')), 'active');
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('3d7248f69f01b78214380dc415639fd8')), 'active');
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardBilling/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      if (!(await Backendless.UserService.isValidLogin())) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('welcome', undefined);
  }
  ___arguments.context.pageData['passwordMessage'] = '';

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/dashboardBilling/components/0e83e5d28c41dcf6f72b118c4c88e952/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('95b257cca481dadd4915f12b5ce32d4c', true);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardBilling/components/fb1fbdabe28d51188b462bd87dce72dd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('fb1fbdabe28d51188b462bd87dce72dd')).slice(-1)[0] == 'active') {
    return (((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('fb1fbdabe28d51188b462bd87dce72dd')).pop());
  } else {
    addItemToList(((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('fb1fbdabe28d51188b462bd87dce72dd')), 'active');
  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardBilling/components/bb690b94bf9a7075334b44a17a591237/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
    var userFirms, userList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  userList = (await Backendless.Data.of('Users').loadRelations((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('profile').setPageSize(1).setOffset(0)));
  ___arguments.context.dataModel['userProfile'] = (userList[0]);
  ___arguments.context.pageData['profilePicture'] = (getObjectProperty((getObjectProperty(___arguments.context.dataModel, 'userProfile')), 'profilePicture'));

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./pages/dashboardBilling/components/cd697b1ab705f70131ac0511b6c8548a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'passwordMessage'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dashboardBilling/components/c0091092e8fe8ec6fd45930d14a76a03/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('myDeals', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardBilling/components/7cde2e1adce2ddb3553aa1f90d89c299/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('addProperty', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardBilling/components/862f4d5a4404019bd47c62aa694248ad/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || !result.hasOwnProperty(propsNamesList[i])) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('myProperties', ({ 'searchInput': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('9a7b440bb2e32ae6007328b8bf079add'), 'searchInput')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardBilling/components/c213b6d263d62fdb2342ea7a137fe655/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('dashboard', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardBilling/components/f008e675089e4e9bbcbb7d452aaea46a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('dashboardFavorites', null);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardBilling/components/bf79192992b28d5efbc7e9455a58d262/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      localStorage.removeItem('currentUser');
  await Backendless.UserService.logout();
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('welcome', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardBilling/components/5fac19aab541250884f33eeea2b22878/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('dashboard', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardBilling/components/151727c1f012be6688a2f9e10c062f12/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })((___arguments.context.getComponentDataStoreByUid('4703a913d8776a487fa652478ea3d92b')['url']), null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardBilling/components/885263991768c31826e86e785b48c388/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Marketplace', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardFavorites/components/4e8adbe6c134efdab161767c10054a23/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('4e8adbe6c134efdab161767c10054a23')).slice(-1)[0] == 'active') {
    ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('4e8adbe6c134efdab161767c10054a23')).pop();
    ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('3d7248f69f01b78214380dc415639fd8')).pop();
    ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('8bcfedb1ed3294418d14fd94c2134779')).pop();
  } else {
    addItemToList(((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('4e8adbe6c134efdab161767c10054a23')), 'active');
    addItemToList(((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('3d7248f69f01b78214380dc415639fd8')), 'active');
    addItemToList(((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('8bcfedb1ed3294418d14fd94c2134779')), 'active');
  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardFavorites/components/0e83e5d28c41dcf6f72b118c4c88e952/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('95b257cca481dadd4915f12b5ce32d4c', true);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardFavorites/components/fb1fbdabe28d51188b462bd87dce72dd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('fb1fbdabe28d51188b462bd87dce72dd')).slice(-1)[0] == 'active') {
    return (((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('fb1fbdabe28d51188b462bd87dce72dd')).pop());
  } else {
    addItemToList(((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('fb1fbdabe28d51188b462bd87dce72dd')), 'active');
  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardFavorites/components/95e1be81ecad4fa235a24429cb5bb083/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboardProfile', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardFavorites/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var user, userList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(await Backendless.UserService.isValidLogin())) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('welcome', undefined);
  }
  ___arguments.context.pageData['currentUser'] = (await Backendless.UserService.getCurrentUser(false));
  ___arguments.context.pageData['favoritesPage'] = true;
  if (getObjectProperty(___arguments.context.pageData, 'currentUser')) {
    if ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('8bcfedb1ed3294418d14fd94c2134779')) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8bcfedb1ed3294418d14fd94c2134779', false);
    }
    userList = (await Backendless.Data.of('Users').loadRelations((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('Profile').setPageSize(1).setOffset(0)));
    user = userList[0];
    ___arguments.context.pageData['profilePhoto'] = (getObjectProperty(user, 'photo'));
  } else {
    if ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('3d7248f69f01b78214380dc415639fd8')) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3d7248f69f01b78214380dc415639fd8', false);
    }
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/dashboardFavorites/components/f8a145cd07633aafaa817fcbf304c61c/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  async onSourceUrlAssignment(___arguments) {
    var mainImage, item;

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if (___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf')['images']) {
    mainImage = (await asyncListFilter((___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf')['images']), async (item) => {


     return ((item['isMain']) == true);
    }));
    return ((mainImage[0])['file']);
  } else {
    return 'styles/images/placeholder-image.jpg';
  }

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/dashboardFavorites/components/23d4212e4d91c2741ff736d10948deda/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('propertyDetails', ({ 'objectId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'objectId')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardFavorites/components/7cde2e1adce2ddb3553aa1f90d89c299/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('addProperty', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardFavorites/components/3fbc30ac21bd9c9159fe93fd54b3169d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3fbc30ac21bd9c9159fe93fd54b3169d', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('696e0a8b0ca2fe22630a89af174585d1', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardFavorites/components/02388b0be609264dbb0a3ec9d55b7b0b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3fbc30ac21bd9c9159fe93fd54b3169d', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('696e0a8b0ca2fe22630a89af174585d1', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardFavorites/components/e93e91ffd41beebcefc624335ec2d6b1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if (getObjectProperty(___arguments.context.pageData, 'currentUser')) {
    await Backendless.Data.of('Users').deleteRelation((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId')), 'favoriteDeals', (['objectId=\'',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'objectId')),'\''].join('')));
    ___arguments.context.pageData['dealListAll'] = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'dealListAll')), async (item) => {


     return ((getObjectProperty(item, 'objectId')) != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'objectId')));
    }));
    ___arguments.context.pageData['dealList'] = (getObjectProperty(___arguments.context.pageData, 'dealListAll'));
  } else {
    localStorage.setItem('favoriteUnauthorized', JSON.stringify((await asyncListFilter(((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('favoriteUnauthorized')), async (item) => {


     return ((getObjectProperty(item, 'objectId')) != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'objectId')));
    }))));
    ___arguments.context.pageData['dealListAll'] = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'dealListAll')), async (item) => {


     return ((getObjectProperty(item, 'objectId')) != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'objectId')));
    }));
    ___arguments.context.pageData['dealList'] = (getObjectProperty(___arguments.context.pageData, 'dealListAll'));
  }
  if (((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('696e0a8b0ca2fe22630a89af174585d1')) && ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('3fbc30ac21bd9c9159fe93fd54b3169d'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('696e0a8b0ca2fe22630a89af174585d1', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3fbc30ac21bd9c9159fe93fd54b3169d', true);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardFavorites/components/0d64fd6989dc161d8cccc5e7aec7afb6/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var filteredList, item;


  if (___arguments.value == 'All') {
    ___arguments.context.pageData['propertyList'] = (___arguments.context.pageData['propertyListAll']);
  } else {
    ___arguments.context.pageData['propertyList'] = (await (async () => {
      async function callback (item) {


        return ((item['offerType']) == ___arguments.value);
      }

      const list = await Promise.all((___arguments.context.pageData['propertyListAll']).map(async source => ({
        source,
        value: await callback(source),
      })));

      const resultList = list.filter(item => item.value)

      return resultList.map(item => item.source)
    })());
  }

  },  
  /* handler:onChange */
  /* content */
}))

define('./pages/dashboardFavorites/components/862f4d5a4404019bd47c62aa694248ad/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var item, index;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || !result.hasOwnProperty(propsNamesList[i])) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  ___arguments.context.pageData['searchInput'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('9a7b440bb2e32ae6007328b8bf079add'), 'searchInput'));
  ___arguments.context.pageData['propertyList'] = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'propertyListAll')), async (item) => {
     index = (getObjectProperty(item, 'title')).toLowerCase().indexOf((getObjectProperty(___arguments.context.pageData, 'searchInput')).toLowerCase()) + 1;
    if (index) {
      return item;
    }


   return ;
  }));
  ___arguments.context.pageData['filterByType'] = 'All';
  ___arguments.context.getComponentDataStoreByUid('9a7b440bb2e32ae6007328b8bf079add')['searchInput'] = '';

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  onDisabledStateAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || !result.hasOwnProperty(propsNamesList[i])) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'propertyListAll')) {
    return false;
  } else {
    return true;
  }

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/dashboardFavorites/components/94dcb5ddec8bd0bddd98bbe9d68dcf1a/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'dealList')) {
    if (!(getObjectProperty(___arguments.context.pageData, 'dealList')).length) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ad2fc20ddbfb3ab873dcffb18fed45f2', false);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('94dcb5ddec8bd0bddd98bbe9d68dcf1a', false);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bc62eef600ea3c05a936ee4da2ff7d1c', true);
    } else {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ad2fc20ddbfb3ab873dcffb18fed45f2', true);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('94dcb5ddec8bd0bddd98bbe9d68dcf1a', true);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bc62eef600ea3c05a936ee4da2ff7d1c', false);
    }
    return (getObjectProperty(___arguments.context.pageData, 'dealList'));
  }

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/dashboardFavorites/components/c0091092e8fe8ec6fd45930d14a76a03/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('myDeals', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardFavorites/components/830a113e0b9aee0d35899d31523f45bd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('pendingProperties', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardFavorites/components/d81c76a2d0564e2216021ea4da66f944/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var favoriteStorage;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['currentUser'] = ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('currentUser'));
  if (getObjectProperty(___arguments.context.pageData, 'currentUser')) {
    ___arguments.context.pageData['dealListAll'] = (await Backendless.Data.of('Users').loadRelations((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('favoriteDeals').setPageSize(10).setOffset(0)));
    ___arguments.context.pageData['dealList'] = (getObjectProperty(___arguments.context.pageData, 'dealListAll'));
    if ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('fe894dc111c9b3b6437bab33f0b3611f')) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fe894dc111c9b3b6437bab33f0b3611f', false);
    }
  } else {
    favoriteStorage = ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('favoriteUnauthorized'));
    if (favoriteStorage && !!favoriteStorage.length) {
      ___arguments.context.pageData['dealListAll'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_c67f868b60fa39474e9fcb25cd396c7f'](favoriteStorage, 'objectId=\'', ' or ', ''))).setPageSize(100)));
    } else {
      ___arguments.context.pageData['dealListAll'] = [];
    }
    ___arguments.context.pageData['dealList'] = (getObjectProperty(___arguments.context.pageData, 'dealListAll'));
    if ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('fe894dc111c9b3b6437bab33f0b3611f')) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fe894dc111c9b3b6437bab33f0b3611f', false);
    }
  }
  if (!(getObjectProperty(___arguments.context.pageData, 'dealList')).length) {
    if ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('bc62eef600ea3c05a936ee4da2ff7d1c')) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bc62eef600ea3c05a936ee4da2ff7d1c', true);
    }
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/dashboardFavorites/components/b86c8f777975548633e41694ff498af7/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'propertyType'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/dashboardFavorites/components/af2fbb7450da4513e96a5a8d35673f84/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('publishedProperties', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardFavorites/components/b5a181b2804f6d4e7dcfa8d6667766ea/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var objectCount;


  if (___arguments.context.pageData['currentUser']) {
    objectCount = (((await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',((___arguments.context.pageData['currentUser'])['objectId']),'\''].join(''))).setProperties(['Count(favoriteProperties) as objectCount']).setPageSize(1)))[0])['objectCount']);
    if (___arguments.value == 10) {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fe894dc111c9b3b6437bab33f0b3611f', true);
      ___arguments.context.pageData['propertyListAll'] = (await Backendless.Data.of('Users').loadRelations(((___arguments.context.pageData['currentUser'])['objectId']), Backendless.LoadRelationsQueryBuilder.create().setRelationName('favoriteProperties').setPageSize(10).setOffset(0)));
      ___arguments.context.pageData['propertyList'] = (___arguments.context.pageData['propertyListAll']);
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fe894dc111c9b3b6437bab33f0b3611f', false);
    } else if (___arguments.value == 20 && objectCount > 10) {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fe894dc111c9b3b6437bab33f0b3611f', true);
      ___arguments.context.pageData['propertyListAll'] = (await Backendless.Data.of('Users').loadRelations(((___arguments.context.pageData['currentUser'])['objectId']), Backendless.LoadRelationsQueryBuilder.create().setRelationName('favoriteProperties').setPageSize(20).setOffset(0)));
      ___arguments.context.pageData['propertyList'] = (___arguments.context.pageData['propertyListAll']);
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fe894dc111c9b3b6437bab33f0b3611f', false);
    } else if (___arguments.value == 30 && objectCount > 20) {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fe894dc111c9b3b6437bab33f0b3611f', true);
      ___arguments.context.pageData['propertyListAll'] = (await Backendless.Data.of('Users').loadRelations(((___arguments.context.pageData['currentUser'])['objectId']), Backendless.LoadRelationsQueryBuilder.create().setRelationName('favoriteProperties').setPageSize(30).setOffset(0)));
      ___arguments.context.pageData['propertyList'] = (___arguments.context.pageData['propertyListAll']);
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fe894dc111c9b3b6437bab33f0b3611f', false);
    } else if (___arguments.value == 50 && objectCount > 30) {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fe894dc111c9b3b6437bab33f0b3611f', true);
      ___arguments.context.pageData['propertyListAll'] = (await Backendless.Data.of('Users').loadRelations(((___arguments.context.pageData['currentUser'])['objectId']), Backendless.LoadRelationsQueryBuilder.create().setRelationName('favoriteProperties').setPageSize(50).setOffset(0)));
      ___arguments.context.pageData['propertyList'] = (___arguments.context.pageData['propertyListAll']);
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fe894dc111c9b3b6437bab33f0b3611f', false);
    } else {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fe894dc111c9b3b6437bab33f0b3611f', true);
      ___arguments.context.pageData['propertyListAll'] = (await Backendless.Data.of('Users').loadRelations(((___arguments.context.pageData['currentUser'])['objectId']), Backendless.LoadRelationsQueryBuilder.create().setRelationName('favoriteProperties').setPageSize(objectCount).setOffset(0)));
      ___arguments.context.pageData['propertyList'] = (___arguments.context.pageData['propertyListAll']);
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fe894dc111c9b3b6437bab33f0b3611f', false);
    }
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/dashboardFavorites/components/c213b6d263d62fdb2342ea7a137fe655/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboard', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardFavorites/components/bf79192992b28d5efbc7e9455a58d262/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      localStorage.removeItem('currentUser');
  await Backendless.UserService.logout();
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardFavorites/components/5fac19aab541250884f33eeea2b22878/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || !result.hasOwnProperty(propsNamesList[i])) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'currentUser')) {
    ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('dashboard', null);
  } else {
    ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('home', null);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardFavorites/components/9a7b440bb2e32ae6007328b8bf079add/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
      if (___arguments.context.pageData['currentUser']) {
    return true;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/dashboardFavorites/components/151727c1f012be6688a2f9e10c062f12/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || !result.hasOwnProperty(propsNamesList[i])) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4703a913d8776a487fa652478ea3d92b'), 'url')), null);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardFavorites/components/14f38ffca392cef659594943fa31961e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
      if ((___arguments.context.pageData['currentUser']) && (___arguments.context.pageData['propertyList']) && !!(___arguments.context.pageData['propertyList']).length) {
    return true;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/dashboardFavorites/components/c11af047f1e99bc9eb1570e93cc1f981/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string') {
    throw new Error('"property name" in "Get object property" block must be "string"')
  }

  if (object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object[propsNamesList[0]]

  for (let i = 1; i < propsNamesList.length; i++) {
    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'searchInput'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/dashboardFavorites/components/c23318dc817cffe2d894d15e13737868/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  onContentAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string') {
    throw new Error('"property name" in "Get object property" block must be "string"')
  }

  if (object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object[propsNamesList[0]]

  for (let i = 1; i < propsNamesList.length; i++) {
    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'searchInput'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dashboardFavorites/components/9c3b1aae34210cfc256682be803f0bd1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string') {
    throw new Error('"property name" in "Get object property" block must be "string"')
  }

  if (object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object[propsNamesList[0]]

  for (let i = 1; i < propsNamesList.length; i++) {
    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['propertyList'] = (getObjectProperty(___arguments.context.pageData, 'propertyListAll'));
  ___arguments.context.pageData['searchInput'] = null;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardFavorites/components/a0fc87d1085854584938c57858989488/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Marketplace', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardFavorites/components/ffd24a62fe3e41720798de58ed6435ff/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('propertyDetails', ({ [`objectId`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'objectId')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardProfile/components/4e8adbe6c134efdab161767c10054a23/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('4e8adbe6c134efdab161767c10054a23')).slice(-1)[0] == 'active') {
    return (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('4e8adbe6c134efdab161767c10054a23')).pop() && ((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('3d7248f69f01b78214380dc415639fd8')).pop());
  } else {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('4e8adbe6c134efdab161767c10054a23')), 'active');
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('3d7248f69f01b78214380dc415639fd8')), 'active');
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardProfile/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      if (!(await Backendless.UserService.isValidLogin())) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('welcome', undefined);
  }
  ___arguments.context.pageData['passwordMessage'] = '';

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/dashboardProfile/components/0e83e5d28c41dcf6f72b118c4c88e952/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('95b257cca481dadd4915f12b5ce32d4c', true);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardProfile/components/fb1fbdabe28d51188b462bd87dce72dd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('fb1fbdabe28d51188b462bd87dce72dd')).slice(-1)[0] == 'active') {
    return (((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('fb1fbdabe28d51188b462bd87dce72dd')).pop());
  } else {
    addItemToList(((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('fb1fbdabe28d51188b462bd87dce72dd')), 'active');
  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardProfile/components/0f130e4fc2f776f5c28689ee19aa7583/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')), 'profilePicture')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')), 'profilePicture'));
    } else {
      return 'https://www.creconnect.com/api/files/web/app/styles/images/placeholder_photo.jpeg';
    }
  } else {
    return 'https://www.creconnect.com/api/files/web/app/styles/images/placeholder_photo.jpeg';
  }

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/dashboardProfile/components/1e2f473ad4ec844ef75d302ae186479b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile'))['photoToDelete'] = (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')), 'profilePicture'));
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('29c2b87a4c0d7cdf71e8bd7325b1145d');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardProfile/components/bb690b94bf9a7075334b44a17a591237/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
    var userFirms, userList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  userList = (await Backendless.Data.of('Users').loadRelations((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('profile').setPageSize(1).setOffset(0)));
  ___arguments.context.dataModel['userProfile'] = (userList[0]);
  ___arguments.context.pageData['profilePicture'] = (getObjectProperty((getObjectProperty(___arguments.context.dataModel, 'userProfile')), 'profilePicture'));

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./pages/dashboardProfile/components/4eabd4231719192cf98d3e7a182bd1bb/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')), 'firstName'));
  }

  },
  /* handler:onValueAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')) {
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile'))['firstName'] = ___arguments.value;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/dashboardProfile/components/04eaefb263be347602c15e9bd7922a6f/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')), 'lastName'));
  }

  },
  /* handler:onValueAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile'))['lastName'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/dashboardProfile/components/1298afc23be1d29f46d432fe3851ebe1/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  async onValueAssignment(___arguments) {
      return ((await Backendless.UserService.getCurrentUser(false))['email'])

  },  
  /* handler:onValueAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      (await Backendless.UserService.getCurrentUser(false))['email'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/dashboardProfile/components/ca7d49bf18d6e65cb545bb082f2e0ec1/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')), 'phone'));
  }

  },
  /* handler:onValueAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile'))['phone'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/dashboardProfile/components/5d094f572a9b37f805f5477721015168/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var loading, error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}


  if (!loading) {
    loading = true;
    try {
      if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')), 'photoToDelete')) {
        await Backendless.Files.remove(encodePath((getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')), 'profilePicture'))));
        (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile'))['photoToDelete'] = null;
        (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile'))['profilePicture'] = null;
        ___arguments.context.pageData['profilePicture'] = null;
      }
      if (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')), 'profilePicture')) {
        ___arguments.context.pageData['profilePicture'] = (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')), 'profilePicture'));
      }
      await Backendless.Data.of('Profile').bulkUpdate( (['objectId = \'',(getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')), 'objectId')),'\''].join('')), ({ 'profilePicture': (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')), 'profilePicture')),'firstName': (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')), 'firstName')),'lastName': (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')), 'lastName')),'phone': (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')), 'phone')),'firm': (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')), 'firm')),'title': (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')), 'title')),'bio': (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')), 'bio')),'website': (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')), 'website')),'location': (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')), 'location')),'firmBio': (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')), 'firmBio')) }) );
      if ((getObjectProperty(___arguments.context.pageData, 'password')) == (getObjectProperty(___arguments.context.pageData, 'passwordConfirm')) && (getObjectProperty(___arguments.context.pageData, 'passwordConfirm'))) {
        (await Backendless.UserService.getCurrentUser(false))['password'] = (getObjectProperty(___arguments.context.pageData, 'password'));
        await Backendless.UserService.update( new Backendless.User((await Backendless.UserService.getCurrentUser(false))) );
        await Backendless.UserService.login( (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'email')), (getObjectProperty(___arguments.context.pageData, 'password')), true  );
        ___arguments.context.pageData['passwordMessage'] = (String('Password changed!'));
        loading = true;
        await (async function() {
        	window.location.reload()
        })();
      } else if (!(getObjectProperty(___arguments.context.pageData, 'passwordConfirm'))) {
        await Backendless.UserService.update( new Backendless.User((await Backendless.UserService.getCurrentUser(false))) );
        ___arguments.context.pageData['passwordMessage'] = (String('Information updated!'));
        loading = false;
      } else {
        ___arguments.context.pageData['passwordMessage'] = (String('Passwords are not identical!'));
        loading = false;
      }

    } catch (error) {
      ___arguments.context.pageData['passwordMessage'] = (getObjectProperty(error, 'message'));
      loading = false;

    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardProfile/components/51a2124ee4f4c44519d53964f17139f9/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  onChange(___arguments) {
      ___arguments.context.pageData['password'] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  onValueAssignment(___arguments) {
      return (___arguments.context.pageData['password'])

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/dashboardProfile/components/29c2b87a4c0d7cdf71e8bd7325b1145d/bundle.js', [], () => ({
  /* content */
  /* handler:onUploadSuccess */
  ['onUploadSuccess'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile'))['profilePicture'] = ___arguments.uploadedFiles;

  },
  /* handler:onUploadSuccess */
  /* handler:onDirectoryAssignment */
  ['onDirectoryAssignment'](___arguments) {
      return '/profile'

  },
  /* handler:onDirectoryAssignment */
  /* handler:onFileNameAssignment */
  ['onFileNameAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')), 'objectId'))

  },
  /* handler:onFileNameAssignment */
  /* content */
}))

define('./pages/dashboardProfile/components/4e785494de4c4b3b72cf0a72c5c12be1/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  onChange(___arguments) {
      ___arguments.context.pageData['passwordConfirm'] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  onValueAssignment(___arguments) {
      return (___arguments.context.pageData['passwordConfirm'])

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/dashboardProfile/components/cd697b1ab705f70131ac0511b6c8548a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'passwordMessage'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dashboardProfile/components/c0091092e8fe8ec6fd45930d14a76a03/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('myDeals', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardProfile/components/7cde2e1adce2ddb3553aa1f90d89c299/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('addProperty', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardProfile/components/862f4d5a4404019bd47c62aa694248ad/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || !result.hasOwnProperty(propsNamesList[i])) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('myProperties', ({ 'searchInput': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('9a7b440bb2e32ae6007328b8bf079add'), 'searchInput')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardProfile/components/c213b6d263d62fdb2342ea7a137fe655/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboard', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardProfile/components/f008e675089e4e9bbcbb7d452aaea46a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboardFavorites', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardProfile/components/bf79192992b28d5efbc7e9455a58d262/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      localStorage.removeItem('currentUser');
  await Backendless.UserService.logout();
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardProfile/components/5fac19aab541250884f33eeea2b22878/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('dashboard', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardProfile/components/151727c1f012be6688a2f9e10c062f12/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })((___arguments.context.getComponentDataStoreByUid('4703a913d8776a487fa652478ea3d92b')['url']), null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardProfile/components/ad8fc8088ac8eee8a7852b0c094c60b8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile'))['firm'] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')), 'firm'));
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/dashboardProfile/components/885263991768c31826e86e785b48c388/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Marketplace', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardProfile/components/7a880547e568f34c7548671bb31a0995/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile'))['title'] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')), 'title'));
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/dashboardProfile/components/96b679244db961c7c796ada3f6eac256/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')), 'bio'));
  }

  },
  /* handler:onValueAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile'))['bio'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/dashboardProfile/components/1ffec948d4529b3fc7bf5169e355cf0e/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile'))['website'] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')), 'website'));
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/dashboardProfile/components/86773fe67207597887a8e658f4a80015/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile'))['location'] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')), 'location'));
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/dashboardProfile/components/c3dfdf3593c8224f4938ebd8cc189e47/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://billing.stripe.com/p/login/14k4juc1uc519DWaEE', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboardProfile/components/4f0bfeefb9183bb872385f88d05f4248/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile')), 'firmBio'));
  }

  },
  /* handler:onValueAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bb690b94bf9a7075334b44a17a591237'), 'userProfile'))['firmBio'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/myDeals/components/4e8adbe6c134efdab161767c10054a23/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('4e8adbe6c134efdab161767c10054a23')).slice(-1)[0] == 'active') {
    return (((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('4e8adbe6c134efdab161767c10054a23')).pop() && ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('3d7248f69f01b78214380dc415639fd8')).pop());
  } else {
    addItemToList(((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('4e8adbe6c134efdab161767c10054a23')), 'active');
    addItemToList(((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('3d7248f69f01b78214380dc415639fd8')), 'active');
  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/myDeals/components/0e83e5d28c41dcf6f72b118c4c88e952/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('95b257cca481dadd4915f12b5ce32d4c', true);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/myDeals/components/fb1fbdabe28d51188b462bd87dce72dd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('fb1fbdabe28d51188b462bd87dce72dd')).slice(-1)[0] == 'active') {
    return (((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('fb1fbdabe28d51188b462bd87dce72dd')).pop());
  } else {
    addItemToList(((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('fb1fbdabe28d51188b462bd87dce72dd')), 'active');
  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/myDeals/components/95e1be81ecad4fa235a24429cb5bb083/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboardProfile', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/myDeals/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      if (!(await Backendless.UserService.isValidLogin())) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('welcome', undefined);
  }
  ___arguments.context.pageData['currentUser'] = ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('currentUser'));
  ___arguments.context.pageData['configuration'] = ((await Backendless.Data.of('ConfigurationProperty').find(Backendless.DataQueryBuilder.create().setProperties('publishPrice').setSortBy('created').setPageSize(1)))[0]);

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/myDeals/components/f8a145cd07633aafaa817fcbf304c61c/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  async ['onSourceUrlAssignment'](___arguments) {
    var mainImage, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'images')) {
    mainImage = (await asyncListFilter((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'images')), async (item) => {


     return ((getObjectProperty(item, 'isMain')) == true);
    }));
    return (getObjectProperty((mainImage[0]), 'file'));
  } else {
    return 'styles/images/placeholder-image.jpg';
  }

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/myDeals/components/23d4212e4d91c2741ff736d10948deda/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('propertyDetails', ({ 'objectId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'objectId')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/myDeals/components/3ca786d389c90f8cb2df79ea6da1ab25/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('propertyDetails', ({ 'objectId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'objectId')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/myDeals/components/7cde2e1adce2ddb3553aa1f90d89c299/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('addDeal', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/myDeals/components/ad8a091d108d221c0cfd74274362f8a8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('addDeal', ({ 'dealId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'objectId')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/myDeals/components/3fbc30ac21bd9c9159fe93fd54b3169d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3fbc30ac21bd9c9159fe93fd54b3169d', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('696e0a8b0ca2fe22630a89af174585d1', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/myDeals/components/02388b0be609264dbb0a3ec9d55b7b0b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3fbc30ac21bd9c9159fe93fd54b3169d', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('696e0a8b0ca2fe22630a89af174585d1', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/myDeals/components/e93e91ffd41beebcefc624335ec2d6b1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  list = ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('compareList'));
  if (list) {
    if ((list.map(item => item['objectId'])).includes((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'objectId')))) {
      list.splice((((list.map(item => item['objectId'])).indexOf(getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'objectId')) + 1) - 1), 1);
      localStorage.setItem('compareList', JSON.stringify(list));
    }
  }
  await Backendless.Data.of('Deal').bulkDelete( (['objectId=\'',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'objectId')),'\''].join('')) );
  ___arguments.context.pageData['dealListAll'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((['ownerId=\'',(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId')),'\''].join(''))).setPageSize(10)));
  ___arguments.context.pageData['dealList'] = (getObjectProperty(___arguments.context.pageData, 'dealListAll'));
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('696e0a8b0ca2fe22630a89af174585d1', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3fbc30ac21bd9c9159fe93fd54b3169d', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/myDeals/components/862f4d5a4404019bd47c62aa694248ad/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var item, index;

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  ___arguments.context.pageData['searchInput'] = (___arguments.context.getComponentDataStoreByUid('9a7b440bb2e32ae6007328b8bf079add')['searchInput']);
  ___arguments.context.pageData['propertyList'] = (await asyncListFilter((___arguments.context.pageData['propertyListAll']), async (item) => {
     index = (item['title']).toLowerCase().indexOf((___arguments.context.pageData['searchInput']).toLowerCase()) + 1;
    if (index) {
      return item;
    }


   return ;
  }));
  ___arguments.context.pageData['filterByType'] = 'All';
  ___arguments.context.getComponentDataStoreByUid('9a7b440bb2e32ae6007328b8bf079add')['searchInput'] = '';

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  onDisabledStateAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || !result.hasOwnProperty(propsNamesList[i])) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'propertyListAll')) {
    return false;
  } else {
    return true;
  }

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/myDeals/components/102888525fc845d2e3f5e791cbc2349a/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'isApproved')) == true && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'isLive')) == true) {
    return ['table__status', 'published'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'isLive')) == false || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'isApproved')) == false) {
    return ['table__status', 'hidden'];
  }

  return ['table__status']

  },
  /* handler:onClassListAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'isReviewed')) == true && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'isApproved')) == true && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'isLive')) == true) {
    return (String('Live'));
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'isApproved')) == false && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'isReviewed')) == false) {
    return (String('Under Review'));
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'isApproved')) == true && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'isLive')) == false) {
    return (String('Expired'));
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'isApproved')) == false && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'isLive')) == true) {
    return (String('Paused'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/myDeals/components/d81c76a2d0564e2216021ea4da66f944/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'currentUser')) {
    ___arguments.context.pageData['dealListAll'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((['ownerId=\'',(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId')),'\''].join(''))).setPageSize(10)));
    ___arguments.context.pageData['dealList'] = (getObjectProperty(___arguments.context.pageData, 'dealListAll'));
  }
  if ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('45750401559dd6d253d1dc326141e343')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('45750401559dd6d253d1dc326141e343', false);
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/myDeals/components/c213b6d263d62fdb2342ea7a137fe655/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboard', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/myDeals/components/5fac19aab541250884f33eeea2b22878/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('dashboard', null);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/myDeals/components/f008e675089e4e9bbcbb7d452aaea46a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboardFavorites', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/myDeals/components/bf79192992b28d5efbc7e9455a58d262/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      localStorage.removeItem('currentUser');
  await Backendless.UserService.logout();
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/myDeals/components/e064a275ef6969f17e1efb15e1ad39e6/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'success'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/myDeals/components/151727c1f012be6688a2f9e10c062f12/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })((___arguments.context.getComponentDataStoreByUid('4703a913d8776a487fa652478ea3d92b')['url']), null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/myDeals/components/8b9faaa10ef40c5dc55bc46ce42a23b2/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'isPaid')) == false) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'configuration')) {
    return (['Pay ',((JSON.stringify((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'configuration')), 'publishPrice')))).replace((new RegExp('\\B(?=(\\d{3})+(?!\\d))', 'g')), ',')),' USD to Publish'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/myDeals/components/6f6779552237ffee7007c2abe2a410cf/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'isPaid')) == true && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'isLive')) == false) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/myDeals/components/eb9be7c807ca55845f3325ff7f8b0dd9/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'isApproved')) == true && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'isLive')) == true)

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf')['isApproved'] = false;
  await Backendless.Data.of('Deal').bulkUpdate( (['objectId=\'',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'objectId')),'\''].join('')), ({ 'isApproved': false }) );

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/myDeals/components/f3944cdb6e576b4a96127b025be25a64/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'isApproved')) == false && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'isReviewed')) == true)

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf')['isApproved'] = 'True';
  await Backendless.Data.of('Deal').bulkUpdate( (['objectId=\'',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'objectId')),'\''].join('')), ({ 'isApproved': true }) );
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboard', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/myDeals/components/149859d8702490f88558c0c1c068e258/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Marketplace', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/myDeals/components/87232fe854f81b584a089348a3585818/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'isApproved')) == true && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'isReviewed')) == true)

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf')['isLive'] = 'True';
  await Backendless.Data.of('Deal').bulkUpdate( (['objectId=\'',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'objectId')),'\''].join('')), ({ 'isLive': true,'isExpired': false,'datePosted': (new Date()) }) );
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboard', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/myDeals/components/d5782c58d1919358af1425683bc801b8/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'isApproved')) == true && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'isReviewed')) == true && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'isLive')) == true) {
    return true;
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'isApproved')) == false && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'isLive')) == true) {
    return true;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/myDeals/components/2ef83428e7b789e805b8377ffd0b4788/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'isLive')) == true) {
    return (['Expires in ',Math.round(((((((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'dateExpire')).valueOf()) - ((new Date()).valueOf())) / 1000) / 60) / 60) / 24),' ','days'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/myDeals/components/45d8b7a8fd09bd688938c522f1861088/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf')['isPaymentPopup'] = true;

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'isPaid')) == false) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/myDeals/components/157aee38342867593fdedd887ce88a68/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
      return ['paymentPopup', 'show']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/myDeals/components/6838c21d52c812944505d4bdf154cded/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf')['isPaymentPopup'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/myDeals/components/cb19fbdd9c4a7b0c50583e286666b668/bundle.js', [], () => ({
  /* content */
  /* handler:onSuccessEvent */
  async ['onSuccessEvent'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Data.of('Deal').bulkUpdate( (['objectId=\'',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'objectId')),'\''].join('')), ({ 'isPaid': true }) );

  },
  /* handler:onSuccessEvent */
  /* content */
}))

define('./pages/passwordRecovery/components/85dae34486144e2efa2d934bfdddb172/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.UserService.restorePassword((getObjectProperty(___arguments.context.dataModel, 'emailInput')));
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f4b88cf4e5c84da35f862bd341b7c2c8', true);
  await new Promise(r => setTimeout(r, 500 || 0));
  ___arguments.context.dataModel['statusMessage'] = 'Please check email to reset password';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4dfa6db7fc98d6a25e8838c87a88e348', true);
  await new Promise(r => setTimeout(r, 1500 || 0));
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', ___arguments.context.appData);

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/passwordRecovery/components/c88214cc934d4d8fa7bb2d71068a7ca3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/propertyDetails/components/1beaf0ba3e282ff8bcd84e18b7d8ad48/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboard', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/propertyDetails/components/fdf8c4339d58bad2be78870cd8a8a4fc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('welcome', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/propertyDetails/components/d75b03f8aacc5b98cd98548f57389118/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var newObject, item, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  ___arguments.context.pageData['deal'] = (await Backendless.Data.of('Deal').findById((getObjectProperty(___arguments.context.pageData, 'objectId')), Backendless.DataQueryBuilder.create().setRelated(['profile'])));
  if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'images')) {
    if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'images')).length <= 1) {
      if (((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('180be18de8bd672c97e8589a31c8f8e8')) && ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('87788328a4949d3881b872b8148f10a8'))) {
        (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('180be18de8bd672c97e8589a31c8f8e8', false);
        (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('87788328a4949d3881b872b8148f10a8', false);
      }
    }
    ___arguments.context.pageData['imagesList'] = (await BackendlessUI.Functions.Custom['fn_4d44373a6f8154e50b5f3c2867692d73']('file', (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'images')), (getObjectProperty(((await asyncListFilter((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'images')), async (item) => {


     return (getObjectProperty(item, 'isMain'));
    }))[0]), 'file'))));
  }
  if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'sources')) {
    ___arguments.context.pageData['parsedSources'] = (await Promise.all((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'sources')).map(async item => {  newObject = ({  });
      var j_list = (Object.keys(item));
      for (var j_index in j_list) {
        j = j_list[j_index];
        newObject = ({ 'name': j,'details': (getObjectProperty(item, j)) });
      }
    ; return newObject;})));
  }
  if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'uses')) {
    ___arguments.context.pageData['parsedUses'] = (await Promise.all((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'uses')).map(async item => {  newObject = ({  });
      var j_list2 = (Object.keys(item));
      for (var j_index2 in j_list2) {
        j = j_list2[j_index2];
        newObject = ({ 'name': j,'details': (getObjectProperty(item, j)) });
      }
    ; return newObject;})));
  }
  if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'highlights')) {
    ___arguments.context.pageData['parsedHighlights'] = (await Promise.all((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'highlights')).map(async item => {  newObject = ({  });
      var j_list3 = (Object.keys(item));
      for (var j_index3 in j_list3) {
        j = j_list3[j_index3];
        newObject = ({ 'name': item });
      }
    ; return newObject;})));
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/propertyDetails/components/8b6fac8e6c8ca2c2d419ca8832433298/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'dealName'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/dd0b0b5d5a38c545a988ef08162d7568/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'fullAddress')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'fullAddress'));
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/ea08a848ba18592866b3400844881403/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'images')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/73add43aba28ba88dbe2de0359f84b03/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.dataModel, 'current')) {
    return ['property__slider-item', 'visible'];
  }

  return ['property__slider-item']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/354b07e8c8ca30f8e2981d6807587368/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'longdescription'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/87788328a4949d3881b872b8148f10a8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var itemsList, currentItemIndex, item, nextItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  itemsList = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('05984058fd4851f880dcdfbb7fc8a62d'));
  currentItemIndex = 0;
  for (var item_index in itemsList) {
    item = itemsList[item_index];
    if (getObjectProperty(item, 'current')) {
      currentItemIndex = itemsList.indexOf(item) + 1;
      item['current'] = false;
    }
  }
  nextItem = itemsList[((currentItemIndex + 1) - 1)];
  if (nextItem) {
    nextItem['current'] = true;
  } else {
    (itemsList[0])['current'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/propertyDetails/components/180be18de8bd672c97e8589a31c8f8e8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var itemsList, currentItemIndex, item, previousItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  itemsList = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('05984058fd4851f880dcdfbb7fc8a62d'));
  currentItemIndex = 0;
  for (var item_index in itemsList) {
    item = itemsList[item_index];
    if (getObjectProperty(item, 'current')) {
      currentItemIndex = itemsList.indexOf(item) + 1;
      item['current'] = false;
    }
  }
  previousItem = itemsList[((currentItemIndex - 1) - 1)];
  if (previousItem) {
    previousItem['current'] = true;
  } else {
    (itemsList.slice(-1)[0])['current'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/propertyDetails/components/eab8a3a3adea3742e4c4f2c5c00db618/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'propertyType'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/62b898f847466ed80c0a40b4cd480b68/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'investmentStrategy'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/ea78647b9b5c5f27d0d87605df6f7d45/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    return (String((JSON.stringify((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'landArea')))).replace((new RegExp('\\B(?=(\\d{3})+(?!\\d))', 'g')), ',')));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/0b182a53a4a82c38c758d54c5e39e82b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    return (String((JSON.stringify((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'rentableArea')))).replace((new RegExp('\\B(?=(\\d{3})+(?!\\d))', 'g')), ',')));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/fdc87c88725cee28373c7e0bc66aa518/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'buildings'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/eaf81222e4f880c88178fa5a88080668/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    return (String((JSON.stringify((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'IRR')))).replace((new RegExp('\\B(?=(\\d{3})+(?!\\d))', 'g')), ',')) + String('%'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/bb786c5869e8b788eda8e31864544df8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    return (String((JSON.stringify((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'CoC')))).replace((new RegExp('\\B(?=(\\d{3})+(?!\\d))', 'g')), ',')) + String('%'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/81088a08f97c6abb643f3f68ff47419b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'parking'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/e23f6d380a5dbcbe1cbd31ee1f8c8d5c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'capitalType'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/4ee8922678784b0ad54816b870a820f8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    return (String('$') + String((JSON.stringify((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'capitalAmount')))).replace((new RegExp('\\B(?=(\\d{3})+(?!\\d))', 'g')), ',')));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/eb280e787c78378ad7e9caab26b8bf38/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'capitalDetail'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/c1a633b754a46d14ab79c265ef8fdc89/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['currentUser'] = ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('currentUser'));
  if (getObjectProperty(___arguments.context.pageData, 'currentUser')) {
    ___arguments.context.pageData['User'] = (await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId')),'\''].join(''))).setRelated(['profile', 'favoriteDeals']).setPageSize(1)));
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/propertyDetails/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var counterName, viewsPerDay, created, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if (!(await Backendless.UserService.isValidLogin())) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('welcome', undefined);
  }
  ___arguments.context.pageData['pageName'] = 'propertyDetails';
  counterName = String(getObjectProperty(___arguments.context.pageData, 'objectId'));
  await Backendless.Counters.addAndGet(counterName, 1);
  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'analytics')) {
      if (!(await asyncListFilter((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'analytics')), async (item) => {
         created = ((new Date((getObjectProperty(item, 'created')))).toLocaleDateString());


       return (created == ((new Date()).toLocaleDateString()));
      })).length) {
        viewsPerDay = (await Backendless.Data.of('DealAnalytics').bulkCreate( [({ 'viewsPerDay': (await Backendless.Counters.get(counterName)) })] ));
        await Backendless.Data.of('Deal').addRelation((getObjectProperty(___arguments.context.pageData, 'deal')), 'analytics', viewsPerDay);
        await Backendless.Counters.reset(counterName);
      }
    }
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/propertyDetails/components/45d7a7e88386a568e378aba893f81a78/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.getComponentDataStoreByUid('ccf8ee78c7382ec86258f93279b80bb8'), 'firstName')),' ',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('ccf8ee78c7382ec86258f93279b80bb8'), 'lastName'))].join(''))

  },
  /* handler:onContentAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('1328d088663d1df83e9889c7ee08fc28');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/propertyDetails/components/8f04b488c9f8dd244c686f880228cc65/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentTab')) == 'General') {
    return 'true';
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/f5e8b87b1f28d6b89638a8fafca8e6fc/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
      ___arguments.context.pageData['currentTab'] = 'General';

  },
  /* handler:onBeforeMount */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['currentTab'] = 'General';

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentTab')) == 'General') {
    return ['dashboard__tab', 'active'];
  }

  return ['dashboard__tab']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/691827e859d4db78f218df98959acd81/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentTab')) == 'Financials') {
    return 'true';
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/5e9dcd683b28317b3bb8984d2008e8e2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['currentTab'] = 'Financials';

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentTab')) == 'Financials') {
    return ['dashboard__tab', 'active'];
  }

  return ['dashboard__tab']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/e1c829e453a5ca98a4a8d5a8c64806c8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('ccf8ee78c7382ec86258f93279b80bb8'), 'phone')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/dd48bd48490d2448f4c81ce700f4e578/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('ccf8ee78c7382ec86258f93279b80bb8'), 'email')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/293dac58a9f4ca98f41a69e8e788206b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Marketplace', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/propertyDetails/components/7c3891d904985428469873a833df2c79/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    return (String('$') + String((JSON.stringify((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'totalSources')))).replace((new RegExp('\\B(?=(\\d{3})+(?!\\d))', 'g')), ',')));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/cabe5de2659806f8ea3849e1c3a32e78/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('b558aa8d4cf88fc84cb80048e249e9cb'), 'name')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/52189d583da82368a22822988b683428/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    return (String('$') + String((JSON.stringify((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'totalUses')))).replace((new RegExp('\\B(?=(\\d{3})+(?!\\d))', 'g')), ',')));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/f0a8145c8a0872985018287843581545/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('$') + String((JSON.stringify((getObjectProperty(___arguments.context.getComponentDataStoreByUid('03bff6627578d688a938bfdec4a8dccf'), 'details')))).replace((new RegExp('\\B(?=(\\d{3})+(?!\\d))', 'g')), ',')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/2007dbe809c8050ab86455c633e8d4e8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('$') + String((JSON.stringify((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bf7cf33852781ecd3ff6ec0888483238'), 'details')))).replace((new RegExp('\\B(?=(\\d{3})+(?!\\d))', 'g')), ',')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/38b527e89fd89a9556a89933b60809c3/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('33c8fa790bead7a8b2183c668eacf3e8'), 'name')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/32fd33084d5f9bd861a3bb2376d8d528/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'parking')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/40a40adb9578c35476129218fd6815d8/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'buildings')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/0b9b50287db804a8e1a85a45107b54d8/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'units')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/c6f8e0ca60cb73a84cc27bbc2eb7c775/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'rentableArea')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/3ced364d69faab386128d1514b26f1c3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'landArea')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/eb087438ff8898fb3c3d812f70b84598/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'propertyType')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/566773e8cfd811b82d6600189892c268/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'investmentStrategy')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/a9b863d4e6dcfc782b882187633337a8/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'capitalDetail')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/4128b38e2f0e466e630883e20a1cd468/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'capitalAmount')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/fbd213d39a06d694637fa71144484663/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'capitalType')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/1828720840e865e876a828c84758b1a8/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'CoC')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/a3081b78008651afa73829b8c16850b8/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'IRR')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/46c89de404a85ad489e8a6081e1bd528/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'sources')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/d5827a180355db080f0f63985d35db38/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'longdescription')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/178584b10897e154be089ef89c0ba1e8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['currentTab'] = 'Attachments';

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentTab')) == 'Attachments') {
    return ['dashboard__tab', 'active'];
  }

  return ['dashboard__tab']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/0b2cb76c6eb738d4105ea6f95f5a12aa/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'attachments'));
  }

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/70fc33310e5212786f7bda27ed476278/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((getObjectProperty(___arguments.context.dataModel, 'file')), true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/propertyDetails/components/6017f2a8cca804c8b83e696ea667be41/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var fileName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}


  fileName = subsequenceFromStartLast((getObjectProperty(___arguments.context.getComponentDataStoreByUid('70fc33310e5212786f7bda27ed476278'), 'file')), (((getObjectProperty(___arguments.context.getComponentDataStoreByUid('70fc33310e5212786f7bda27ed476278'), 'file')).lastIndexOf('/') + 1 + 1) - 1));

  return fileName

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/8cfd50c8d408c608297884dff4f82ae8/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentTab')) == 'Attachments') {
    return 'true';
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/27f8a6e8991ff5782458522816b8bf8f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var contacts, createdInquiry, newInquiry, newInquiryId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Messaging.sendEmailFromTemplate('Introduction Email', Backendless.EmailEnvelope.create({ addresses: [(getObjectProperty(___arguments.context.getComponentDataStoreByUid('ccf8ee78c7382ec86258f93279b80bb8'), 'email'))], ccAddresses: [(getObjectProperty(___arguments.context.pageData, 'senderEmail'))], bccAddresses: undefined, query: '', uniqueEmails: false }), ({ 'dealName': (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'dealName')),'senderPhone': (getObjectProperty(___arguments.context.pageData, 'senderPhone')),'senderFirm': (getObjectProperty(___arguments.context.pageData, 'senderFirm')),'senderName': (getObjectProperty(___arguments.context.pageData, 'senderName')),'senderMessage': (getObjectProperty(___arguments.context.pageData, 'senderMessage')) }), undefined);
  newInquiry = ({ 'senderEmail': (getObjectProperty(___arguments.context.pageData, 'senderEmail')),'senderMessage': (getObjectProperty(___arguments.context.pageData, 'senderMessage')),'senderPhone': (getObjectProperty(___arguments.context.pageData, 'senderPhone')),'senderFirm': (getObjectProperty(___arguments.context.pageData, 'senderFirm')),'senderName': (getObjectProperty(___arguments.context.pageData, 'senderName')),'recipient': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ccf8ee78c7382ec86258f93279b80bb8'), 'email')) });
  newInquiryId = (await Backendless.Data.of('Inquiry').bulkCreate( [newInquiry] ));
  await Backendless.Data.of('Deal').addRelation((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'objectId')), 'Inquiry', (['objectId=\'',newInquiryId,'\''].join('')));
  contacts = String(getObjectProperty(___arguments.context.pageData, 'objectId'));
  await Backendless.Counters.addAndGet(contacts, 1);
  ___arguments.context.getComponentDataStoreByUid('ccf8ee78c7382ec86258f93279b80bb8')['sentMessage'] = 'Message Sent!';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/propertyDetails/components/3b12ec08d808338ea478e623e303dd97/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['senderName'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/propertyDetails/components/2668869b26e8dec86e2845042c1f16d8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['senderEmail'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/propertyDetails/components/70b8efb8e3581808238ae096800829e8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['senderMessage'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/propertyDetails/components/730eadde714dcc18acd876a88ce879a8/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    if (!!(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'profile')).length) {
      return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'profile'));
    }
  }

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/f77851b6e2e8cf68274855564e7b1b18/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['senderFirm'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/propertyDetails/components/b40886c8ca6e992fac380ee2c88963b8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['senderPhone'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/propertyDetails/components/7cd8204658982b3891d7a902123a9a38/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if (getObjectProperty(___arguments.context.pageData, 'currentUser')) {
    if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('ca6a3d1874e8ee18a0b872d89e4a43c3')).includes('clicked')) {
      await Backendless.Data.of('Users').deleteRelation((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId')), 'favoriteDeals', [({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'objectId')) })]);
      ((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('ca6a3d1874e8ee18a0b872d89e4a43c3')).pop();
    } else {
      addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('ca6a3d1874e8ee18a0b872d89e4a43c3')), 'clicked');
      await Backendless.Data.of('Users').addRelation((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId')), 'favoriteDeals', [({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'objectId')) })]);
    }
  } else {
    if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('ca6a3d1874e8ee18a0b872d89e4a43c3')).includes('clicked')) {
      ((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('ca6a3d1874e8ee18a0b872d89e4a43c3')).pop();
      localStorage.setItem('favoriteUnauthorized', JSON.stringify((await asyncListFilter(((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('favoriteUnauthorized')), async (item) => {


       return ((getObjectProperty(item, 'objectId')) != (getObjectProperty(___arguments.context.pageData, 'objectId')));
      }))));
    } else {
      if ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('favoriteUnauthorized')) {
        localStorage.setItem('favoriteUnauthorized', JSON.stringify((addItemToList(((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('favoriteUnauthorized')), ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'objectId')) })))));
      } else {
        localStorage.setItem('favoriteUnauthorized', JSON.stringify([({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'objectId')) })]));
      }
      addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('ca6a3d1874e8ee18a0b872d89e4a43c3')), 'clicked');
    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/propertyDetails/components/ccf8ee78c7382ec86258f93279b80bb8/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
      ___arguments.context.dataModel['sentMessage'] = 'Send Message';

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/propertyDetails/components/44fb627810d3f5bd7cd77a8737582625/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'firm')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'firm'));
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/2b68364e98e83338ef1fef08dc0e0428/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'zoning'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/c4e8f0a861162089667548989d286b1a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'zoning')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/61d64df155685c3be668853b87529bfc/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'cFAR')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/2a786e780b082d642bb8fe18d8c89b28/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'cFAR'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/e7887a3656129068bb88bc18450a6f79/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'bFAR')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/eaf83d73ed88fa0826c4141682a9ef2b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'bFAR'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/e944391d3738ce9ffb9814a84e18f522/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'purchasePrice')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/f92804c84acdef18cc6862fc1a4ecbd8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    return (String('$') + String((JSON.stringify((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'purchasePrice')))).replace((new RegExp('\\B(?=(\\d{3})+(?!\\d))', 'g')), ',')));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/bfc89b089767423854cf5763e9583ea8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    return (String('$') + String((JSON.stringify((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'rNOI')))).replace((new RegExp('\\B(?=(\\d{3})+(?!\\d))', 'g')), ',')));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/2da84dde0579a38886c88d38c7d8ec73/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    return (String('$') + String((JSON.stringify((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'terminalValue')))).replace((new RegExp('\\B(?=(\\d{3})+(?!\\d))', 'g')), ',')));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/f6a80a78e23c376512986ae3ba5b35c8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    return (String('$') + String((JSON.stringify((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'purchasePSF')))).replace((new RegExp('\\B(?=(\\d{3})+(?!\\d))', 'g')), ',')));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/d968ccb887c78d98330eef084d786758/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    return (String('$') + String((JSON.stringify((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'exitPSF')))).replace((new RegExp('\\B(?=(\\d{3})+(?!\\d))', 'g')), ',')));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/4a98978fda7279b8082814e87e483488/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    return (String((JSON.stringify((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'exitCap')))).replace((new RegExp('\\B(?=(\\d{3})+(?!\\d))', 'g')), ',')) + String('%'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/c768bcb8ae51d7d82a887b134d28adf8/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'purchasePrice')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/377f6268c8f8f4e81b58e412f6613208/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'exitCap')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/bd386e68eebf588ad3f85578151b2a28/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'rNOI')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/21b45df9ed08138828284d6be1b91cc5/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'terminalValue')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/2687c013371b749aa9bfa0cd77c1b358/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'exitPSF')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/64181af8cb871d082e48bea84068d568/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'units'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/b984f9e8130852a9ff68e028366cfbe8/bundle.js', [], () => ({
  /* content */
  /* handler:onClose */
  async ['onClose'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('b984f9e8130852a9ff68e028366cfbe8');

  },
  /* handler:onClose */
  /* content */
}))

define('./pages/propertyDetails/components/00b45318702843f8240f2df898380fe7/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    return (String(JSON.stringify((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'hold')))) + String(' Years'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/d4012e25bf589db80ed8f9a91fca1a28/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'hold')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/65c8026ef54f7138d0f8cd27e8d893f8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'shortDescription'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/480822224dc62918b50767dc7c383a28/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'shortDescription')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/2d13b3b8cd286e8f1e88465752d8dcf8/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'capitalType')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/047cf0b9b9b85908c98cc218cde87ad8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'walt'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/800823381528f8a8a47e9fa86b58d6d6/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'walt')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/a0885579d12be6a622e816fd3279e381/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('b984f9e8130852a9ff68e028366cfbe8');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/propertyDetails/components/1328d088663d1df83e9889c7ee08fc28/bundle.js', [], () => ({
  /* content */
  /* handler:onClose */
  async ['onClose'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('1328d088663d1df83e9889c7ee08fc28');

  },
  /* handler:onClose */
  /* handler:titleLogic */
  ['titleLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.getComponentDataStoreByUid('ccf8ee78c7382ec86258f93279b80bb8'), 'firstName')),' ',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('ccf8ee78c7382ec86258f93279b80bb8'), 'lastName'))].join(''))

  },
  /* handler:titleLogic */
  /* content */
}))

define('./pages/propertyDetails/components/dbb86f2803a8919a5291b4cb6a7881f9/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deal')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'deal')), 'highlights')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/545668d83ed1d128c4d894489bd80b6b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('d608e108beb8cc480648c538460841d8'), 'name')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/propertyDetails/components/24187828978817c85b080b08d4e8f558/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
      console.log('abc');

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/rolodex/components/4e8adbe6c134efdab161767c10054a23/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('4e8adbe6c134efdab161767c10054a23')).slice(-1)[0] == 'active') {
    ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('4e8adbe6c134efdab161767c10054a23')).pop();
    ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('3d7248f69f01b78214380dc415639fd8')).pop();
    ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('8bcfedb1ed3294418d14fd94c2134779')).pop();
  } else {
    addItemToList(((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('4e8adbe6c134efdab161767c10054a23')), 'active');
    addItemToList(((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('3d7248f69f01b78214380dc415639fd8')), 'active');
    addItemToList(((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('8bcfedb1ed3294418d14fd94c2134779')), 'active');
  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/rolodex/components/0e83e5d28c41dcf6f72b118c4c88e952/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('95b257cca481dadd4915f12b5ce32d4c', true);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/rolodex/components/fb1fbdabe28d51188b462bd87dce72dd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('fb1fbdabe28d51188b462bd87dce72dd')).slice(-1)[0] == 'active') {
    return (((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('fb1fbdabe28d51188b462bd87dce72dd')).pop());
  } else {
    addItemToList(((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('fb1fbdabe28d51188b462bd87dce72dd')), 'active');
  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/rolodex/components/95e1be81ecad4fa235a24429cb5bb083/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('dashboardProfile', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/rolodex/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var user, userList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['currentUser'] = (await Backendless.UserService.getCurrentUser(false));
  ___arguments.context.pageData['favoritesPage'] = true;
  if (getObjectProperty(___arguments.context.pageData, 'currentUser')) {
    if ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('8bcfedb1ed3294418d14fd94c2134779')) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8bcfedb1ed3294418d14fd94c2134779', false);
    }
    userList = (await Backendless.Data.of('Users').loadRelations((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('Profile').setPageSize(1).setOffset(0)));
    user = userList[0];
    ___arguments.context.pageData['profilePhoto'] = (getObjectProperty(user, 'photo'));
  } else {
    if ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('3d7248f69f01b78214380dc415639fd8')) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3d7248f69f01b78214380dc415639fd8', false);
    }
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/rolodex/components/f8a145cd07633aafaa817fcbf304c61c/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  async onSourceUrlAssignment(___arguments) {
    var mainImage, item;

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if (___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf')['images']) {
    mainImage = (await asyncListFilter((___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf')['images']), async (item) => {


     return ((item['isMain']) == true);
    }));
    return ((mainImage[0])['file']);
  } else {
    return 'styles/images/placeholder-image.jpg';
  }

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/rolodex/components/23d4212e4d91c2741ff736d10948deda/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('propertyDetails', ({ 'propertyId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'objectId')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/rolodex/components/7cde2e1adce2ddb3553aa1f90d89c299/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('addProperty', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/rolodex/components/3fbc30ac21bd9c9159fe93fd54b3169d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3fbc30ac21bd9c9159fe93fd54b3169d', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('696e0a8b0ca2fe22630a89af174585d1', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/rolodex/components/02388b0be609264dbb0a3ec9d55b7b0b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3fbc30ac21bd9c9159fe93fd54b3169d', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('696e0a8b0ca2fe22630a89af174585d1', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/rolodex/components/e93e91ffd41beebcefc624335ec2d6b1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if (getObjectProperty(___arguments.context.pageData, 'currentUser')) {
    await Backendless.Data.of('Users').deleteRelation((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId')), 'favoriteDeals', (['objectId=\'',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'objectId')),'\''].join('')));
    ___arguments.context.pageData['dealListAll'] = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'dealListAll')), async (item) => {


     return ((getObjectProperty(item, 'objectId')) != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'objectId')));
    }));
    ___arguments.context.pageData['dealList'] = (getObjectProperty(___arguments.context.pageData, 'dealListAll'));
  } else {
    localStorage.setItem('favoriteUnauthorized', JSON.stringify((await asyncListFilter(((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('favoriteUnauthorized')), async (item) => {


     return ((getObjectProperty(item, 'objectId')) != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'objectId')));
    }))));
    ___arguments.context.pageData['dealListAll'] = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'dealListAll')), async (item) => {


     return ((getObjectProperty(item, 'objectId')) != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'objectId')));
    }));
    ___arguments.context.pageData['dealList'] = (getObjectProperty(___arguments.context.pageData, 'dealListAll'));
  }
  if (((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('696e0a8b0ca2fe22630a89af174585d1')) && ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('3fbc30ac21bd9c9159fe93fd54b3169d'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('696e0a8b0ca2fe22630a89af174585d1', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3fbc30ac21bd9c9159fe93fd54b3169d', true);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/rolodex/components/0d64fd6989dc161d8cccc5e7aec7afb6/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var filteredList, item;


  if (___arguments.value == 'All') {
    ___arguments.context.pageData['propertyList'] = (___arguments.context.pageData['propertyListAll']);
  } else {
    ___arguments.context.pageData['propertyList'] = (await (async () => {
      async function callback (item) {


        return ((item['offerType']) == ___arguments.value);
      }

      const list = await Promise.all((___arguments.context.pageData['propertyListAll']).map(async source => ({
        source,
        value: await callback(source),
      })));

      const resultList = list.filter(item => item.value)

      return resultList.map(item => item.source)
    })());
  }

  },  
  /* handler:onChange */
  /* content */
}))

define('./pages/rolodex/components/862f4d5a4404019bd47c62aa694248ad/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var item, index;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || !result.hasOwnProperty(propsNamesList[i])) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  ___arguments.context.pageData['searchInput'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('9a7b440bb2e32ae6007328b8bf079add'), 'searchInput'));
  ___arguments.context.pageData['propertyList'] = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'propertyListAll')), async (item) => {
     index = (getObjectProperty(item, 'title')).toLowerCase().indexOf((getObjectProperty(___arguments.context.pageData, 'searchInput')).toLowerCase()) + 1;
    if (index) {
      return item;
    }


   return ;
  }));
  ___arguments.context.pageData['filterByType'] = 'All';
  ___arguments.context.getComponentDataStoreByUid('9a7b440bb2e32ae6007328b8bf079add')['searchInput'] = '';

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  onDisabledStateAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || !result.hasOwnProperty(propsNamesList[i])) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'propertyListAll')) {
    return false;
  } else {
    return true;
  }

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/rolodex/components/94dcb5ddec8bd0bddd98bbe9d68dcf1a/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'dealList')) {
    if (!(getObjectProperty(___arguments.context.pageData, 'dealList')).length) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ad2fc20ddbfb3ab873dcffb18fed45f2', false);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('94dcb5ddec8bd0bddd98bbe9d68dcf1a', false);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bc62eef600ea3c05a936ee4da2ff7d1c', true);
    } else {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ad2fc20ddbfb3ab873dcffb18fed45f2', true);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('94dcb5ddec8bd0bddd98bbe9d68dcf1a', true);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bc62eef600ea3c05a936ee4da2ff7d1c', false);
    }
    return (getObjectProperty(___arguments.context.pageData, 'dealList'));
  }

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/rolodex/components/c0091092e8fe8ec6fd45930d14a76a03/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('myDeals', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/rolodex/components/830a113e0b9aee0d35899d31523f45bd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('pendingProperties', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/rolodex/components/6a49595cdffb099ea4dd997458c4b486/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async onMounted(___arguments) {
      ___arguments.context.pageData['headerList'] = (await Backendless.Data.of('Header').find(Backendless.DataQueryBuilder.create().setSortBy('created').setPageSize(10)));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/rolodex/components/d81c76a2d0564e2216021ea4da66f944/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var favoriteStorage;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['currentUser'] = ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('currentUser'));
  if (getObjectProperty(___arguments.context.pageData, 'currentUser')) {
    ___arguments.context.pageData['dealListAll'] = (await Backendless.Data.of('Users').loadRelations((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('favoriteDeals').setPageSize(10).setOffset(0)));
    ___arguments.context.pageData['dealList'] = (getObjectProperty(___arguments.context.pageData, 'dealListAll'));
    if ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('fe894dc111c9b3b6437bab33f0b3611f')) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fe894dc111c9b3b6437bab33f0b3611f', false);
    }
  } else {
    favoriteStorage = ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('favoriteUnauthorized'));
    if (favoriteStorage && !!favoriteStorage.length) {
      ___arguments.context.pageData['dealListAll'] = (await Backendless.Data.of('Deal').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_c67f868b60fa39474e9fcb25cd396c7f'](favoriteStorage, 'objectId=\'', ' or ', ''))).setPageSize(100)));
    } else {
      ___arguments.context.pageData['dealListAll'] = [];
    }
    ___arguments.context.pageData['dealList'] = (getObjectProperty(___arguments.context.pageData, 'dealListAll'));
    if ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('fe894dc111c9b3b6437bab33f0b3611f')) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fe894dc111c9b3b6437bab33f0b3611f', false);
    }
  }
  if (!(getObjectProperty(___arguments.context.pageData, 'dealList')).length) {
    if ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('bc62eef600ea3c05a936ee4da2ff7d1c')) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bc62eef600ea3c05a936ee4da2ff7d1c', true);
    }
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/rolodex/components/b86c8f777975548633e41694ff498af7/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e4bd1eb5f93f39116db61ddce461bcf'), 'propertyType'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/rolodex/components/af2fbb7450da4513e96a5a8d35673f84/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('publishedProperties', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/rolodex/components/b5a181b2804f6d4e7dcfa8d6667766ea/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var objectCount;


  if (___arguments.context.pageData['currentUser']) {
    objectCount = (((await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',((___arguments.context.pageData['currentUser'])['objectId']),'\''].join(''))).setProperties(['Count(favoriteProperties) as objectCount']).setPageSize(1)))[0])['objectCount']);
    if (___arguments.value == 10) {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fe894dc111c9b3b6437bab33f0b3611f', true);
      ___arguments.context.pageData['propertyListAll'] = (await Backendless.Data.of('Users').loadRelations(((___arguments.context.pageData['currentUser'])['objectId']), Backendless.LoadRelationsQueryBuilder.create().setRelationName('favoriteProperties').setPageSize(10).setOffset(0)));
      ___arguments.context.pageData['propertyList'] = (___arguments.context.pageData['propertyListAll']);
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fe894dc111c9b3b6437bab33f0b3611f', false);
    } else if (___arguments.value == 20 && objectCount > 10) {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fe894dc111c9b3b6437bab33f0b3611f', true);
      ___arguments.context.pageData['propertyListAll'] = (await Backendless.Data.of('Users').loadRelations(((___arguments.context.pageData['currentUser'])['objectId']), Backendless.LoadRelationsQueryBuilder.create().setRelationName('favoriteProperties').setPageSize(20).setOffset(0)));
      ___arguments.context.pageData['propertyList'] = (___arguments.context.pageData['propertyListAll']);
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fe894dc111c9b3b6437bab33f0b3611f', false);
    } else if (___arguments.value == 30 && objectCount > 20) {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fe894dc111c9b3b6437bab33f0b3611f', true);
      ___arguments.context.pageData['propertyListAll'] = (await Backendless.Data.of('Users').loadRelations(((___arguments.context.pageData['currentUser'])['objectId']), Backendless.LoadRelationsQueryBuilder.create().setRelationName('favoriteProperties').setPageSize(30).setOffset(0)));
      ___arguments.context.pageData['propertyList'] = (___arguments.context.pageData['propertyListAll']);
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fe894dc111c9b3b6437bab33f0b3611f', false);
    } else if (___arguments.value == 50 && objectCount > 30) {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fe894dc111c9b3b6437bab33f0b3611f', true);
      ___arguments.context.pageData['propertyListAll'] = (await Backendless.Data.of('Users').loadRelations(((___arguments.context.pageData['currentUser'])['objectId']), Backendless.LoadRelationsQueryBuilder.create().setRelationName('favoriteProperties').setPageSize(50).setOffset(0)));
      ___arguments.context.pageData['propertyList'] = (___arguments.context.pageData['propertyListAll']);
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fe894dc111c9b3b6437bab33f0b3611f', false);
    } else {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fe894dc111c9b3b6437bab33f0b3611f', true);
      ___arguments.context.pageData['propertyListAll'] = (await Backendless.Data.of('Users').loadRelations(((___arguments.context.pageData['currentUser'])['objectId']), Backendless.LoadRelationsQueryBuilder.create().setRelationName('favoriteProperties').setPageSize(objectCount).setOffset(0)));
      ___arguments.context.pageData['propertyList'] = (___arguments.context.pageData['propertyListAll']);
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fe894dc111c9b3b6437bab33f0b3611f', false);
    }
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/rolodex/components/c213b6d263d62fdb2342ea7a137fe655/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('dashboard', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/rolodex/components/bf79192992b28d5efbc7e9455a58d262/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      localStorage.removeItem('currentUser');
  await Backendless.UserService.logout();
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('welcome', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/rolodex/components/5fac19aab541250884f33eeea2b22878/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || !result.hasOwnProperty(propsNamesList[i])) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'currentUser')) {
    ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('dashboard', null);
  } else {
    ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('home', null);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/rolodex/components/9a7b440bb2e32ae6007328b8bf079add/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
      if (___arguments.context.pageData['currentUser']) {
    return true;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/rolodex/components/151727c1f012be6688a2f9e10c062f12/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || !result.hasOwnProperty(propsNamesList[i])) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4703a913d8776a487fa652478ea3d92b'), 'url')), null);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/rolodex/components/14f38ffca392cef659594943fa31961e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
      if ((___arguments.context.pageData['currentUser']) && (___arguments.context.pageData['propertyList']) && !!(___arguments.context.pageData['propertyList']).length) {
    return true;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/rolodex/components/c11af047f1e99bc9eb1570e93cc1f981/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string') {
    throw new Error('"property name" in "Get object property" block must be "string"')
  }

  if (object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object[propsNamesList[0]]

  for (let i = 1; i < propsNamesList.length; i++) {
    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'searchInput'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/rolodex/components/c23318dc817cffe2d894d15e13737868/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  onContentAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string') {
    throw new Error('"property name" in "Get object property" block must be "string"')
  }

  if (object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object[propsNamesList[0]]

  for (let i = 1; i < propsNamesList.length; i++) {
    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'searchInput'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/rolodex/components/9c3b1aae34210cfc256682be803f0bd1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string') {
    throw new Error('"property name" in "Get object property" block must be "string"')
  }

  if (object.hasOwnProperty(propPath)) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object[propsNamesList[0]]

  for (let i = 1; i < propsNamesList.length; i++) {
    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['propertyList'] = (getObjectProperty(___arguments.context.pageData, 'propertyListAll'));
  ___arguments.context.pageData['searchInput'] = null;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/rolodex/components/a0fc87d1085854584938c57858989488/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Marketplace', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_04683d082ff6c42f49153135fb46e76c/logic/fb1fbdabe28d51188b462bd87dce72dd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('fb1fbdabe28d51188b462bd87dce72dd')).slice(-1)[0] == 'active') {
    return (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('fb1fbdabe28d51188b462bd87dce72dd')).pop());
  } else {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('fb1fbdabe28d51188b462bd87dce72dd')), 'active');
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_1846ec66aed493a8b0befbf4a2bc70a3/logic/4e8adbe6c134efdab161767c10054a23/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('4e8adbe6c134efdab161767c10054a23')).slice(-1)[0] == 'active') {
    return (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('4e8adbe6c134efdab161767c10054a23')).pop() && ((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('4e8adbe6c134efdab161767c10054a23')).pop());
  } else {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('4e8adbe6c134efdab161767c10054a23')), 'active');
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('3d7248f69f01b78214380dc415639fd8')), 'active');
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5e13d984d4ba1809e9e687c9c257d319/logic/4e8adbe6c134efdab161767c10054a23/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('4e8adbe6c134efdab161767c10054a23')).slice(-1)[0] == 'active') {
    return (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('4e8adbe6c134efdab161767c10054a23')).pop() && ((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('3d7248f69f01b78214380dc415639fd8')).pop());
  } else {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('4e8adbe6c134efdab161767c10054a23')), 'active');
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('3d7248f69f01b78214380dc415639fd8')), 'active');
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_e7699e6b9be92478123c86356f48118c/logic/d38d79d41de44699f2a29f6b5d923b23/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboardProfile', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_e7699e6b9be92478123c86356f48118c/logic/34ce590fbd9c00923ea3a4ad6d9b38e8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('myDeals', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_e7699e6b9be92478123c86356f48118c/logic/de173fbe8d032c1be02544dfd1fcd1f9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboardFavorites', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_e7699e6b9be92478123c86356f48118c/logic/93e9b29e2528679bd03eb592b716cb1b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      localStorage.removeItem('currentUser');
  await Backendless.UserService.logout();
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_e7699e6b9be92478123c86356f48118c/logic/7d38f178be98ee8630a7c0a8e2f8387b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('addDeal', ___arguments.context.appData);

  },
  /* handler:onClick */
  /* content */
}))

